import { all, fork } from 'redux-saga/effects'
import accountSagas from './accountSagas'
import app from './appSagas'
import bulkRefinalizeSagas from './bulkRefinalizeSagas'
import bulkRedoSagas from './bulkRedoSagas'
import bulkCancelSagas from './bulkCancelSagas'
import bulkStatusSagas from './bulkStatusSagas'
import comments from './commentSagas'
import customerDetails from './customerDetailsSagas'
import mixups from './mixupSagas'
import orderDetails from './orderDetailsSagas'
import productSagas from './productSagas'
import projectSagas from './projectSagas'
import refunds from './refundsSagas'
import sbsForProductionOrder from './sbsForProductionOrderSagas'
import search from './searchSagas'
import widget from './widgetSaga'
import redo from './redoSagas'

export default function* sagas() {
  yield all([
    fork(accountSagas),
    fork(app),
    fork(orderDetails),
    fork(mixups),
    fork(comments),
    fork(customerDetails),
    fork(search),
    fork(sbsForProductionOrder),
    fork(widget),
    fork(refunds),
    fork(productSagas),
    fork(projectSagas),
    fork(bulkRefinalizeSagas),
    fork(bulkRedoSagas),
    fork(bulkCancelSagas),
    fork(bulkStatusSagas),
    fork(redo),
  ])
}
