import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import * as commentActions from '../actions/commentActions'
import * as actions from '../actions/orderDetailsActions'
import * as productActions from '../actions/productViewActions'
import * as widgetActions from '../actions/widgetActions'
import {
  FileInfoResponse,
  isCommentRelatedToOrder,
  NoteRequest,
  NoteResponse,
  OrderDetails,
  RestoreFromColdStorageRequest,
  WidgetRequest,
} from '../api'
import { GlobalPanels, PanelsCollection, WidgetNotification, WidgetSidePanel } from '../models'
import { OrderDetailsViewStore } from './states'

const initialState: OrderDetailsViewStore = {
  staticPanels: {
    orderNotes: { ...GlobalPanels.orderNotes },
    orderTraces: { ...GlobalPanels.orderTraces },
    showByScan: { ...GlobalPanels.showByScan },
    orderCancel: { ...GlobalPanels.orderCancel },
    orderReset: { ...GlobalPanels.orderReset },
    orderRedo: { ...GlobalPanels.orderRedo },
    orderPrice: { ...GlobalPanels.orderPrice },
    changeAddress: { ...GlobalPanels.changeAddress },
  },
  productPanels: {},
  foldedCards: [],
  allCardsFolded: false,
  paymentLinkIsOpen: false,
  isNotesInEditMode: false,
  notesFilter: undefined,
  showNotesCreatedNotification: false,
  waitingPredictionResponse: false,
  restoringFiles: [],
  discountLoading: false,
}

function processWidgetRequestAction(state: OrderDetailsViewStore, action: PayloadAction<WidgetRequest>) {
  const panelKey = widgetActions.getWidgetPanelKey(action.payload.productCode)
  const panel: WidgetSidePanel = state.productPanels[panelKey]!
  panel.isLoading = true
  panel.notification = undefined
}

export const orderDetailsView = createReducer<OrderDetailsViewStore>(initialState, {
  [actions.receivedOrderDetails.type]: (state, action: PayloadAction<OrderDetails>) => {
    const products = action.payload.products
    const panels: PanelsCollection = {}
    if (products) {
      products.forEach((p) => {
        const optionsKey = `${p.id}_options`
        panels[optionsKey] = {
          accessKey: optionsKey,
          title: 'Product options',
          isOpen: false,
          isLoading: false,
        }

        const reuploadKey = `${p.id}_reupload`
        panels[reuploadKey] = {
          accessKey: reuploadKey,
          tooltipKey: 'reupload',
          title: 'Product reupload',
          isOpen: false,
          isLoading: false,
        }

        const cancelReuploadKey = `${p.id}_cancel_reupload`
        panels[cancelReuploadKey] = {
          accessKey: cancelReuploadKey,
          tooltipKey: 'reupload',
          title: 'Invalidate product reupload',
          isOpen: false,
          isLoading: false,
        }

        const widgetKey = widgetActions.getWidgetPanelKey(p.id)
        panels[widgetKey] = {
          accessKey: widgetKey,
          title: 'Widget',
          isOpen: false,
          isLoading: false,
        }

        const linesKey = `${p.id}_lines`
        panels[linesKey] = {
          accessKey: linesKey,
          title: 'Product lines',
          isOpen: false,
          isLoading: false,
        }
      })
    }

    const newState = {
      ...initialState,
      code: action.payload.id,
      productPanels: panels,
      isLoading: false,
      restoringFiles: state.code !== action.payload.id ? [] : state.restoringFiles,
    }

    return newState
  },
  [actions.togglePanel.type]: (state, action: PayloadAction<string>) => {
    const panel = state.staticPanels[action.payload] || state.productPanels[action.payload]
    if (panel) {
      panel.isOpen = !panel.isOpen
      panel.isLoading = false
    }
  },
  [commentActions.sendComment.type]: (state, action: PayloadAction<NoteRequest>) => {
    if (isCommentRelatedToOrder(state.code, action)) {
      state.staticPanels.orderNotes.isLoading = true
    }
  },
  [commentActions.sentComment.type]: (state, action: PayloadAction<NoteResponse>) => {
    if (isCommentRelatedToOrder(state.code, action)) {
      state.staticPanels.orderNotes.isLoading = false
      state.showNotesCreatedNotification = true
    }
  },
  [actions.submitOperation.type]: (state) => {
    state.staticPanels.orderCancel.isOpen = false
    state.staticPanels.orderReset.isOpen = false
  },
  [actions.togglePaymentLink.type]: (state) => {
    state.paymentLinkIsOpen = !state.paymentLinkIsOpen
  },
  [actions.toggleNotesPanelMode.type]: (state, action: PayloadAction<boolean | undefined>) => {
    if (action.payload === undefined) {
      state.isNotesInEditMode = !state.isNotesInEditMode
    } else {
      state.isNotesInEditMode = action.payload
    }
    state.showNotesCreatedNotification = false
  },
  [actions.changeNotesFilter.type]: (state, action: PayloadAction<string | undefined>) => {
    state.notesFilter = action.payload
    state.isNotesInEditMode = false
    state.showNotesCreatedNotification = false
  },
  [widgetActions.createRequest.type]: processWidgetRequestAction,
  [widgetActions.deleteRequest.type]: processWidgetRequestAction,
  [widgetActions.recreateRequest.type]: processWidgetRequestAction,
  [widgetActions.showNotification.type]: (state, action: PayloadAction<WidgetNotification>) => {
    const panelKey = widgetActions.getWidgetPanelKey(action.payload.productCode)
    const panel: WidgetSidePanel = state.productPanels[panelKey]!
    panel.isLoading = false
    panel.notification = action.payload
  },
  [productActions.requestRestoreFromColdStorage.type]: (state, action: PayloadAction<RestoreFromColdStorageRequest>) => {
    state.restoringFiles.push(action.payload.storageId)
  },
  [productActions.receivedRestoringFile.type]: (state, action: PayloadAction<FileInfoResponse>) => {
    if (action.payload.coldStorage === false) {
      state.restoringFiles = state.restoringFiles.filter((x) => x !== action.payload.storageId)
    }
  },
  [actions.requestDiscountView.type]: (state) => {
    state.discountLoading = true
  },
  [actions.receivedDiscountView.type]: (state) => {
    state.discountLoading = false
  },
  [actions.receivedDiscountViewError.type]: (state, action: PayloadAction<string>) => {
    state.discountLoading = false
    state.discountLookupError = action.payload
  },
  [actions.toggleAllDetails.type]: (state) => {
    state.allCardsFolded = !state.allCardsFolded
    state.foldedCards.forEach((_, rowIndex) => (state.foldedCards[rowIndex] = state.allCardsFolded))
  },
  [actions.toggleCardDetails.type]: (state, action: PayloadAction<0>) => {
    const rowIndex = action.payload
    state.foldedCards[rowIndex] = state.foldedCards[rowIndex] !== undefined ? !state.foldedCards[rowIndex] : !state.allCardsFolded
  },
})
