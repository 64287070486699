import queryString from 'query-string'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Container, Row } from 'reactstrap'
import * as commentActions from '../actions/commentActions'
import * as actions from '../actions/orderDetailsActions'
import { codes, GlobalConfiguration, LogMessage, NoteRequest, Product, OrderEvents, OrderStatus } from '../api'
import * as OrderTypes from '../api/order'
import ActionLink, { ActionLinkSettings } from '../components/generic/ActionLink'
import ActionList from '../components/generic/ActionList'
import OrderCancelForm from '../components/forms/OrderCancelForm'
import CollapsableContainer from '../components/generic/CollapsableContainer'
import Grid, { GridColumn } from '../components/generic/Grid'
import NavigationLinks from '../components/generic/NavigationLinks'
import OrderHeader from '../components/generic/OrderHeader'
import QuestionmarkWithTooltip from '../components/generic/QuestionmarkWithTooltip'
import StudentenProductCard from '../components/generic/StudentenProductCard'
import DeliveryInfoPanel from '../components/panels/DeliveryInfoPanel'
import OrderPricePanel from '../components/panels/OrderPricePanel'
import { BadgeSetting, CommonProps, GlobalPanels, TogglePanel } from '../models'
import { AppStore, OrderDetailsStore, OrderDetailsViewStore, SbsPanelViewStore } from '../reducers/states'
import SidePanel from '../components/panels/SidePanel'
import LazyContainer from '../components/generic/LazyContainer'
import routes from '../route/routes'
import { LogApiInstance as LogService } from '../services/LogApiService'
import { OrderDetailsServiceInstance as Service } from '../services/OrderDetailsService'
import { copyToClipboard, threeValuedLogic, toDateTime } from '../utils'

interface Props extends CommonProps, OrderDetailsViewStore, OrderDetailsStore {
  configuration: GlobalConfiguration
  data: OrderTypes.OrderDetails
  sbsPanelData: SbsPanelViewStore
}

class StudentenOrderDetailsView extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
    this.panelToggle = this.panelToggle.bind(this)
    this.sendNote = this.sendNote.bind(this)
    this.toggleCancel = this.toggleCancel.bind(this)
    this.toggleNotes = this.toggleNotes.bind(this)
    this.toggleProductNotes = this.toggleProductNotes.bind(this)
    this.toggleNotesMode = this.toggleNotesMode.bind(this)
    this.toggleTraces = this.toggleTraces.bind(this)
    this.togglePrice = this.togglePrice.bind(this)
    this.submitOrderUpdate = this.submitOrderUpdate.bind(this)
  }

  componentDidMount() {
    if (!this.props.data || this.props.data.id !== this.props.match.params.orderId) {
      this.props.dispatch(actions.requestOrderDetails(this.props.match.params.orderId))
    }
  }

  panelToggle(key: string) {
    this.props.dispatch(actions.togglePanel(key))
  }

  sendNote(request: NoteRequest) {
    this.props.dispatch(commentActions.sendComment(request))
  }

  toggleNotes() {
    this.props.dispatch(actions.changeNotesFilter())
    this.panelToggle(GlobalPanels.orderNotes.accessKey)
  }

  toggleProductNotes(id: string) {
    this.props.dispatch(actions.changeNotesFilter(id))
    this.panelToggle(GlobalPanels.orderNotes.accessKey)
  }

  toggleNotesMode(isEditMode?: boolean) {
    this.props.dispatch(actions.toggleNotesPanelMode(isEditMode))
  }

  toggleTraces() {
    this.panelToggle(GlobalPanels.orderTraces.accessKey)
  }

  togglePrice() {
    this.panelToggle(GlobalPanels.orderPrice.accessKey)
  }

  render() {
    const data = this.props.data
    if (!data) {
      return <div />
    }

    const parentBadgeSettings = Service.resolveOrderStatusBadgeSettings(data, false)

    const pricePanel: TogglePanel = {
      ...this.props.staticPanels[GlobalPanels.orderPrice.accessKey],
      toggle: this.togglePrice,
    }

    const cancelPanel: TogglePanel = {
      ...this.props.staticPanels[GlobalPanels.orderCancel.accessKey],
      toggle: this.toggleCancel,
    }

    const actions = []

    actions.push({ text: 'Add note', onClick: this.toggleNotes })
    if (data.status !== OrderStatus.Cancelled) {
      actions.push({ text: 'Cancel', onClick: this.toggleCancel })
    }    

    const tracesPanel: TogglePanel = {
      ...this.props.staticPanels[GlobalPanels.orderTraces.accessKey],
      toggle: this.toggleTraces,
    }

    return (
      <Container fluid>
        <Row>
          <NavigationLinks orderCode={data.id} source={data.source} configuration={this.props.configuration} />
        </Row>
        <Row>{this.renderOrderHeader()}</Row>
        <Row>{this.renderOverviewGrid()}</Row>
        <Row>
          <ActionList actions={actions} />
        </Row>
        <SidePanel {...cancelPanel}>
            <LazyContainer isLoading={cancelPanel.isLoading}>
              <OrderCancelForm onSubmit={this.submitOrderUpdate} data={data} />
            </LazyContainer>
          </SidePanel>
        <Row className="mt-3">
          <h3>Shipping address</h3>
          {this.renderAddress(data.shippingAddress, data.deliveryInfo)}
          <DeliveryInfoPanel {...tracesPanel} data={data} />
          <ActionList
            actions={[
              { text: 'Delivery info', onClick: this.toggleTraces },
              {
                text: 'Copy to clipboard',
                onClick: () => (data.shippingAddress ? this.copyAddressToClipboard(data.shippingAddress) : {}),
              },
            ]}
          />
        </Row>
        <Row className="mt-3">
          <CollapsableContainer title="Billing address" folded={true}>
            {this.renderAddress(data.billingAddress)}
            <ActionList
              actions={[
                {
                  text: 'Copy to clipboard',
                  onClick: () => (data.billingAddress ? this.copyAddressToClipboard(data.billingAddress) : {}),
                },
              ]}
            />
          </CollapsableContainer>
        </Row>
        <Row className="mt-3">
          <h3 className="mb-0">Product details</h3>
        </Row>
        {data.products &&
          data.products.map((p: Product) => this.renderProductCard(p, data.vendor, parentBadgeSettings))}
        <Row className="mt-3">
          <h3>Price</h3>
          {this.renderPriceGrid()}
        </Row>
        <Row>
          <OrderPricePanel {...pricePanel} data={data} showRefundButton={false} />
          <ActionList actions={[{ text: 'Price details', onClick: this.togglePrice }]} />
        </Row>
      </Container>
    )
  }

  renderOrderHeader() {
    const notesPanel: TogglePanel = {
      ...this.props.staticPanels[GlobalPanels.orderNotes.accessKey],
      toggle: this.toggleNotes,
    }

    return (
      <OrderHeader
        order={this.props.data}
        notesPanelDetails={{
          notesPanel: notesPanel,
          isNotesInEditMode: this.props.isNotesInEditMode,
          notesFilter: this.props.notesFilter,
          sendNote: this.sendNote,
          showNotesCreatedNotification: this.props.showNotesCreatedNotification,
          toggleNotes: this.toggleNotes,
          toggleNotesMode: this.toggleNotesMode,
        }}></OrderHeader>
    )
  }

  renderOverviewGrid() {
    const data = this.props.data
    const columns: GridColumn<OrderTypes.OrderDetails>[] = [
      {
        title: 'Order date',
        value: (order) => toDateTime(order.createdDateTime),
      },
      {
        title: 'Vendor',
        value: (order) => order.channel,
      },
      {
        title: 'Shipments #',
        value: (order) => Service.getShipmentsCount(order) || '?',
      },
      {
        title: 'Products #',
        value: (order) => (order.products && order.products.length) || '?',
      },
      { title: 'Name', value: (order) => order.customer && order.customer.name },
      {
        title: 'Email',
        value: (order) =>
          order.customer && <Link to={routes.CUSTOMERS_VIEW({ email: order.customer.email })}>{order.customer.email}</Link>,
      },
    ]

    return <Grid columns={columns} data={[data]} disableCopyOnClick={true} />
  }

  renderPriceGrid() {
    const data = this.props.data

    const columns: GridColumn<OrderTypes.Price>[] = [
      { title: 'Currency', value: (price) => price.currency },
      { title: 'Sub Total', value: (price) => price.subTotal },
      { title: 'Shipping cost', value: (price) => price.shipment },
      { title: 'Discount', value: (price) => price.discount },
      { title: 'Total', value: (price) => price.total },
      { title: 'Discount code', value: (price) => price.discountCode },
      { title: 'Tax', value: (price) => price.tax },
    ]

    return <Grid columns={columns} data={[data.price]} />
  }

  toggleCancel() {
    this.panelToggle(GlobalPanels.orderCancel.accessKey)
  }

  submitOrderUpdate(payload: OrderTypes.InternalOperationRequest) {
    this.props.dispatch(actions.submitOperation(payload))
  }

  renderPayments(payments?: OrderTypes.PaymentHistory[]) {
    const columns: GridColumn<OrderTypes.PaymentHistory>[] = [
      { title: 'Date', value: (p) => toDateTime(p.received) },
      { title: 'Method', value: (p) => p.method },
      { title: 'Status', value: (p) => p.event },
      { title: 'Success', value: (p) => threeValuedLogic(p.success, 'Yes', 'No', '') },
      { title: 'Reference', value: (p) => p.providerReference, tdClass: 'pr-1' },
      { title: '', value: (p) => this.renderAdyenLink(p.providerReference) },
      { title: 'Amount', value: (p) => p.paidAmount },
      { title: 'Currency', value: (p) => p.currency },
      { title: 'Reason', value: (p) => p.reason },
    ]

    return <Grid columns={columns} data={payments} />
  }

  renderAdyenLink(reference?: string) {
    if (!reference) {
      return
    }

    return <ActionLink href={`${this.props.configuration.adyenUrl}${reference}`} />
  }

  renderAddress(address?: OrderTypes.Address, deliveryInfo?: OrderTypes.TraceWithProducts[]) {
    const secondLinePresent = address && !!address.addressLine2

    const columns: GridColumn<OrderTypes.Address>[] = [
      { title: 'Name', value: (s) => s.customerName },
      { title: 'Company', value: (s) => s.companyName },
    ]

    if (secondLinePresent) {
      columns.push({ title: 'Line 1', value: (s) => s.addressLine1 })
      columns.push({ title: 'Line 2', value: (s) => s.addressLine2 })
    } else {
      columns.push({ title: 'Address', value: (s) => s.addressLine1 })
    }

    const countryName = (address && address.country) || ''
    if (address && !countryName) {
      LogService.error(
        new LogMessage(
          {
            message: 'Failed to resolve the country.',
            country: countryName,
          },
          codes.orderDetailsError
        )
      )
    }

    const additionalColumns: GridColumn<OrderTypes.Address>[] = [
      { title: 'Zip', value: (s) => s.zipCode },
      { title: 'City', value: (s) => s.city },
      { title: this.renderCountryColumnTitle(), value: (s) => countryName },
      { title: 'Phone', value: (s) => s.customerPhone },
    ]

    if (address && address.type !== 0) {
      additionalColumns.push({ title: 'Type', value: (s) => OrderTypes.FulfillmentShipmentDeliveryTypeNames[s.type] })
    }

    if (deliveryInfo) {
      additionalColumns.push({
        title: 'Scanned for shipment',
        value: () => {
          const scannedLinkText = Service.resolveShipmentsText(deliveryInfo)
          if (scannedLinkText) {
            return <ActionLink className="attention" onClick={this.toggleTraces} text={scannedLinkText} />
          }

          return <span className="secondary-font">N/A</span>
        },
      })
    }

    return <Grid columns={columns.concat(additionalColumns)} data={address ? [address] : undefined} />
  }

  renderCountryColumnTitle() {
    return (
      <span>
        Country{' '}
        <QuestionmarkWithTooltip tooltipId="countryCodes_tooltip" placement="right" size={20}>
          <div>
            <ActionLink text="Check country info" href="https://wiki.albelli.net/wiki/CC_Vendor_differences" />
          </div>
        </QuestionmarkWithTooltip>
      </span>
    )
  }

  copyAddressToClipboard(address: OrderTypes.Address): void {
    let result: string = ''

    const append = function (result: string, add?: string): string {
      if (!add) {
        return result
      }

      return result + '\n' + add
    }

    result = append(result, address.customerName)
    result = append(result, address.companyName)
    result = append(result, address.addressLine1)
    result = append(result, address.addressLine2)
    result = append(result, address.zipCode + ' ' + address.city)
    result = append(result, address.country ? address.country : '')

    copyToClipboard(result.trim())
  }

  extractPanel(accessKey: string): TogglePanel | undefined {
    const panel = this.props.productPanels[accessKey]
    return (
      panel && {
        ...panel,
        toggle: () => panel && this.panelToggle(panel.accessKey),
      }
    )
  }

  isHighlighted(product: Product) {
    const search = queryString.parse(this.props.location.search)

    return product.id === search.selected
  }

  renderProductCard(
    product: Product,
    vendor: string,
    parentStatus: BadgeSetting
  ) {
    const previewSrc = Service.resolveProductPreviewUrl(product)
    const toggleProductNotes = () => this.toggleProductNotes(product.id)
    const widgetPreviewBaseUrl = this.props.configuration.widgetPreviewUrls[vendor]
    const widgetPreviewUrl = widgetPreviewBaseUrl && `${widgetPreviewBaseUrl}${product.widgetId}&securityId=${product.securityId}`;

    const actions: ActionLinkSettings[] = []

    actions.push({ text: 'Notes', onClick: toggleProductNotes })

    const relatedNotes = Service.filterNotes(this.props.data.notes, product.id)
    const events = Service.resolveProductEvents(this.props.data.orderEvents, product.id)
    const resetCounter = Service.resolveOrderEventsByType(events || [], OrderEvents.Reprocess).length

    return (
      <StudentenProductCard
        key={product.id}
        product={product}
        productEvents={events}
        vendor={vendor}
        actions={actions}
        previewSrc={previewSrc}
        parentStatus={parentStatus}
        highlighted={this.isHighlighted(product)}
        notesCount={relatedNotes.length}
        hasSbs={false}
        resetCounter={resetCounter}
        widgetPreviewUrl={widgetPreviewUrl}
        onDelayLinkClick={this.toggleTraces}
      />
    )
  }
}

function mapStateToProps(state: AppStore) {
  return {
    sbsPanelData: state.sbsPanel,
    ...state.orderDetails,
    ...state.orderDetailsView,
    configuration: state.global.configuration,
  } as Props
}

export default connect(mapStateToProps)(StudentenOrderDetailsView)
