export interface ProductsRequest {
  orderId: string
  productId: string
  contentId: string
}

export interface Product {
  id: string
  productContentId?: string
  externalReferenceId?: string
  preview?: ProductPreview
  quantity: number
  basePricePerItem?: number
  shippingCosts?: number
  shippingIsIncludeStartPrice?: boolean
  generalDiscount?: number
  bulkDiscount?: number
  totalDiscount?: number
  totalPricePerItem?: number
  totalPrice?: number
  title?: string
  reUploadPermitted?: boolean
  status: ProductStatus
  extras: ProductExtra[]
  lines: ProductLine[]
  options?: ProductOption[]
  editor: Editor
  productId: string
  albumId?: number
  widgetId?: string
  securityId?: string
  pdfStorageId?: string
  pdfUrl?: string
  pdfIsInColdStorage?: boolean
  albxStorageId?: string
  albxUrl?: string
  albxIsInColdStorage?: boolean
  imageArchiveStorageId?: string
  imageArchiveUrl?: string
  imageArchiveIsInColdStorage?: boolean
}

export type ProductStatus = 'Cancelled' | 'Received' | 'Confirmed'

export interface Editor {
  name?: string
  version?: string
}

export interface ProductExtra {
  name?: string
  optionCode?: string
  quantity?: number
  total?: number
  pricePerSheet?: number
}

export interface ProductLine {
  id: string
  status: OrderLineStatus
}

export interface ProductOption {
  key: string
  value: string
}

// TODO: review me
export interface ProductPreview {
  id: string
  url?: string
}

export const ProductReuploadReasons: { [id: number]: string } = {
  20: 'Customer has to change image/text/etc due to technical issue',
  21: 'Customer misunderstood product/editor and needs to change product',
  22: 'Customer selected wrong start month for calendar',
  23: 'Customer wants to change/edit product instead of a regular reset',
  24: 'Customer wants to change/edit product that is in production',
  25: 'Customer must turn off enhancement',
  26: 'Other',
}

export interface ProductRequest {
  productCode: string
  vendor?: string
  appVersion?: string
}

export interface RestartFinalizerRequestBase extends ProductRequest {
  orderCode: string
  contentId: string
  editor?: string
}

export interface RestartFinalizerRequest extends RestartFinalizerRequestBase {
  enhancement: RestartFinalizerEnhancementType
  reasonCode?: number
  reasonPhrase?: string
  reasonComment?: string
}

export enum RestartFinalizerEnhancementType {
  On = 1,
  Off = 2,
}

export interface RestoreFromColdStorageRequest {
  storageId: string
  orderCode?: string
  productCode?: string
  productContentId: string
}

export interface FileInfoResponse {
  storageId: string
  coldStorage?: boolean
}

export const ArticleTypeNames: { [id: string]: string } = {
  Aluminium: 'Aluminium',
  Calendar: 'Calendar',
  Canvas: 'Canvas',
  Forex: 'Forex (Mounted Print)',
  GreetingCard: 'Greeting Card',
  HardCoverPhotoBook: 'Hardcover Photobook',
  HardPhotoCoverPhotoBook: 'Hardcover Photobook with PhotoCover',
  Mug: 'Mug',
  Perspex: 'Perspex',
  PhoneCase: 'Phone Case',
  PhotoOnWood: 'Photo on Wood',
  PhotoSheets: 'Photo Sheets',
  Poster: 'Poster',
  Prints: 'Prints',
  SoftCoverPhotoBook: 'Softcover Photobook',
  WireOPhotoBook: 'Wire-O Photobook',
  WoodenPhotoBlock: 'Wooden Photo Block',
  PaddedPhotoBook: 'Padded PhotoBook',
  MomentsPhotoBook: 'Little Moments Photobook',
  MagicMug: 'Magic Mug',
  LoveMug: 'Love Mug'
}

export const CoverColorNames: { [id: string]: string } = {
  '01103': 'Linen Silver',
  '01105': 'Linen Black',
  '01203': 'Linen Blue',
  '01209': 'White satin',
  '01210': 'White shimmer',
  '01303': 'Linen Red',
  '01304': 'Linen Orange',
  '01305': 'Linen Nature',
  '01401': 'Linen Purple',
  '03101': 'Leather Black',
  '03301': 'Leather Brown',
}

export const PrintSurfaceNames: { [id: string]: string } = {
  Duplex: 'Double-Sided',
  Simplex: 'Single-Sided',
}

export const FrameColorNames: { [id: string]: string } = {
  BK: 'Black',
  WH: 'White',
  NT: 'Natural',
}

export const PaperTypeNames: { [id: string]: string } = {
  MATTE: 'Matte',
  GLOSSY: 'Glossy',
  EXTRAMATTE: 'ExtraMatte',
  EXTRAWHITE: 'ExtraWhite',
}

export const ColorNames: { [id: string]: string } = {
  PK: 'Pink',
  BL: 'Blue',
  GN: 'Green',
  RD: 'Red',
  BK: 'Black',
  WT: 'White'
}

export const MaterialNames: { [id: string]: string } = {
  CAN: 'Canvas',
  VEL: 'Velvet',
}

export const PhotoSideNames: { [id: string]: string } = {
  SGL: 'Single',
  DBL: 'Double',
}

export const BorderNames: { [id: string]: string } = {
  BorderOption: 'With Border',
}

export const PassepartoutNames: { [id: string]: string } = {
  WH5: 'WH5',
}

export interface WidgetRequest {
  orderCode: string
  productCode: string
}

export interface ProductContentView {
  id: string
  albxStorageId?: string
  albxDownloadUrl?: string
  albxIsInColdStorage?: boolean
  pdfStorageId?: string
  pdfDownloadUrl?: string
  pdfIsInColdStorage?: boolean
  imageArchiveStorageId?: string
  imageArchiveDownloadUrl?: string
  imageArchiveIsInColdStorage?: boolean
}

export interface ProductContentLog {
  date: string
  status: string
  message?: string
  source: string
}

export enum RestartFinalizerReason {
  Unknown = 0,
  RemoveEnhancementAfterDelivery = 1,
  RemoveEnhancementBeforeDelivery = 2,
  PdfNotAvailable = 3,
  ITIssuePBM = 4,
  ITIssueOOPS = 5,
  ImageArchiveNotAvailable = 6,
  Other = 100,
}

export const RestartFinalizerReasonNames: { [type: number]: string } = {
  [RestartFinalizerReason.RemoveEnhancementAfterDelivery]: 'Remove enhancement after product delivery',
  [RestartFinalizerReason.RemoveEnhancementBeforeDelivery]: 'Remove enhancement before product delivery',
  [RestartFinalizerReason.PdfNotAvailable]: 'PDF not available',
  [RestartFinalizerReason.ImageArchiveNotAvailable]: 'ImageArchive not available',
  [RestartFinalizerReason.ITIssuePBM]: 'IT issue: PBM',
  [RestartFinalizerReason.ITIssueOOPS]: 'IT issue: OOPS',
  [RestartFinalizerReason.Other]: 'Other',
}

export enum OrderLineStatus {
  Unknown = 0,
  WaitingForProduction = 1,
  InProduction = 2,
  ScannedForShipment = 3,
  Cancelled = 4,
  Error = 5,
  Packed = 6,
}

export enum ProductionOrderProductStatus {
  Unknown = 0,
  Accepted = 1,
  WaitingForProduction = 2,
  InProduction = 3,
  Delayed = 4,
  ScannedForShipment = 5,
  Cancelled = 6,
  Error = 7,
  Packed = 8,
  PartiallyShipped = 9,
}

export enum ProductFileType {
  Unknown = 0,
  Albx = 1,
  Pdf = 2,
  ImageArchive = 3,
}
