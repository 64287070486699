import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import * as bulkOperationActions from '../actions/bulkOperationsActions'
import { NoteBulkRequest, NoteBulkStatusResponse } from '../api'
import { BulkRefinalizeRequest, BulkRefinalizeResponse } from '../api/refinalize'
import { BulkRedoRequest, BulkRedoStatusResponse } from '../api/redo'
import { BulkGetStatusRequest, BulkGetStatusResponse } from '../api/status'
import { BulkOperationsViewStore } from './states'
import { BulkCancelRequest, BulkCancelStatusResponse } from '../api/cancel'

const initialState: BulkOperationsViewStore = {
  isLoadingBulk: false,
  tabIndex: 0,
  bulkNoteStatusResponse: {} as NoteBulkStatusResponse,
  bulkRedoStatusResponse: {} as BulkRedoStatusResponse,
  bulkCancelStatusResponse: {} as BulkCancelStatusResponse,
  bulkGetStatusResponse: {} as BulkGetStatusResponse,
  bulkRefinalizeResponse: {} as BulkRefinalizeResponse,
}

export const bulkOperationsView = createReducer<BulkOperationsViewStore>(initialState, {
  [bulkOperationActions.bulkCommentRequest.type]: (state, action: PayloadAction<NoteBulkRequest>) => {
    return state
  },
  [bulkOperationActions.bulkCommentStatusReceived.type]: (state, action: PayloadAction<NoteBulkStatusResponse>) => {
    state.bulkNoteStatusResponse = action.payload
    return state
  },
  [bulkOperationActions.bulkRedoRequest.type]: (state, action: PayloadAction<BulkRedoRequest>) => {
    return state
  },
  [bulkOperationActions.bulkRedoStatusResponse.type]: (state, action: PayloadAction<BulkRedoStatusResponse>) => {
    state.bulkRedoStatusResponse = action.payload
    return state
  },
  [bulkOperationActions.bulkCancelRequest.type]: (state, action: PayloadAction<BulkCancelRequest>) => {
    return state
  },
  [bulkOperationActions.bulkCancelStatusResponse.type]: (state, action: PayloadAction<BulkCancelStatusResponse>) => {
    state.bulkCancelStatusResponse = action.payload
    return state
  },
  [bulkOperationActions.bulkGetStatusRequest.type]: (state, action: PayloadAction<BulkGetStatusRequest>) => {
    return state
  },
  [bulkOperationActions.bulkGetStatusResponse.type]: (state, action: PayloadAction<BulkGetStatusResponse>) => {
    state.bulkGetStatusResponse = action.payload
    return state
  },
  [bulkOperationActions.bulkResultsReset.type]: (state, action: PayloadAction<any>) => {
    state.bulkRefinalizeResponse.response = []
    state.bulkNoteStatusResponse.valid = false
    state.bulkRedoStatusResponse.valid = false
    state.bulkCancelStatusResponse.valid = false
    state.bulkGetStatusResponse.valid = false
    state.bulkRefinalizeResponse.valid = false
    return state
  },
  [bulkOperationActions.tabIndexChange.type]: (state, action: PayloadAction<number>) => {
    state.tabIndex = action.payload
    return state
  },
  [bulkOperationActions.bulkRefinalizeRequest.type]: (state, action: PayloadAction<BulkRefinalizeRequest>) => {
    return state
  },
  [bulkOperationActions.bulkRefinalizeReceived.type]: (state, action: PayloadAction<BulkRefinalizeResponse>) => {
    state.bulkRefinalizeResponse = action.payload
    return state
  },
})
