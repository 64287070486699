import React, { useEffect, useState } from 'react'
import { GetPickupPointsResponse, PickupPoint } from '../../api'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import './PudoOptions.scss'

const dayToString = new Map([
  [0, 'Monday'],
  [1, 'Tuesday'],
  [2, 'Wednesday'],
  [3, 'Thursday'],
  [4, 'Friday'],
  [5, 'Saturday'],
  [6, 'Sunday'],
])

interface PudoOptionsProps {
  initialPickupPoint?: PickupPoint
  pickupPoints?: GetPickupPointsResponse
  onPickupPointSelection(pickupPoint: PickupPoint): void
}

export const PudoOptions = ({ initialPickupPoint, pickupPoints, onPickupPointSelection }: PudoOptionsProps) => {
  const [selectedPudo, setSelectedPudo] = useState<PickupPoint | undefined>(initialPickupPoint)
  const [modal, setModal] = useState<boolean>(false)

  useEffect(() => {
    setSelectedPudo(initialPickupPoint)
  }, [initialPickupPoint])

  const toggleModal = (pup?: PickupPoint): void => {
    if (pup?.name) {
      setSelectedPudo(pup)
    }
    setModal((prevState) => !prevState)
  }

  const selectPudo = (event: any): void => {
    const pudoId = event.currentTarget.value
    const selectedPudoPoint = pickupPoints?.pickupPoints?.find((x) => x.id === pudoId)!
    setSelectedPudo(selectedPudoPoint)
  }

  const renderPickupPoint = (pup: PickupPoint) => {
    return (
      <div className="pudo-info-box" style={{ display: 'block' }}>
        <input
          type="radio"
          id={pup.id}
          name="pickupPoint"
          checked={pup.id === selectedPudo?.id}
          onChange={(e) => selectPudo(e)}
          className="pudo-radio"
          value={pup.id}
        />
        <h2 className="pudo-info-title">{pup.name}</h2>
        <p className="pudo-info-address">
          {pup.address?.street} {pup.address?.postalCode} {pup.address?.city} ({pup.distance} {pup.distanceUnit})
        </p>
        <p className="pudo-info-city">{pup.address.city}</p>
        <a className="mr-4" onClick={() => toggleModal(pup)}>
          Opening hours
        </a>
      </div>
    )
  }

  return (
    <>
      <Modal isOpen={modal} toggle={toggleModal} className="bo-modal">
        <ModalHeader>{selectedPudo?.name}</ModalHeader>
        <ModalBody>
          <div className="pudo-info-hours">
            <p>
              <b>Opening hours</b>
            </p>
            <ul>
              {selectedPudo?.openingTimes?.map((op) => (
                <li key={op.day.toString()}>
                  {dayToString.get(op.day)}: {op.openHours[0].openingTime}hs - {op.openHours[0].closingTime}hs
                </li>
              ))}
            </ul>
          </div>
        </ModalBody>
        <ModalFooter>
          <button onClick={() => toggleModal()}>Close</button>
        </ModalFooter>
      </Modal>
      {pickupPoints?.pickupPoints?.map((x) => renderPickupPoint(x))}
      <button onClick={() => onPickupPointSelection(selectedPudo!)} disabled={selectedPudo === undefined}>
        Next
      </button>
    </>
  )
}
