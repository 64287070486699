import { PayloadAction } from '@reduxjs/toolkit'
import { push } from 'connected-react-router'
import _ from 'lodash'
import queryString from 'query-string'
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import * as global from '../actions/globalActions'
import * as actions from '../actions/orderDetailsActions'
import * as sbsActions from '../actions/sbsActions'
import {
  ChangeAddressRequest,
  DataSource,
  InternalOperationRequest,
  InvoiceDownloadRequest,
  OrderDetails,
  OrderListItemModel,
  ProductPreview,
} from '../api'
import routes from '../route/routes'
import { ApiServiceInstance } from '../services/ApiService'
import { Errors } from '../services/ErrorMessageService'

function getErrorMessage(e: any) {
  return (
    (e.response && ((e.response.data && e.response.data.message) || e.response.statusText)) ||
    e.message ||
    'Something went wrong.'
  )
}

function* fetchPreviews(dataSource: DataSource, previews: ProductPreview[]) {
  try {
    const detailedPreviews = yield requestPreviewsByIds(
      dataSource,
      previews.map((x) => x.id)
    )
    yield put(actions.receivedOrderDetailsPreview(detailedPreviews))
  } catch (e) {
    if (e.response) {
      if (e.response.status === 404) {
        yield put(global.showWarning('Some preview images could be missed'))
        return
      }
    }

    yield put(Errors.toErrorAction(e))
  }
}

function* fetchOrderDetails(action: PayloadAction<string>) {
  const orderCode = action.payload
  try {
    yield put(global.showSpinner())

    const details: OrderDetails = yield call(ApiServiceInstance.get, `orders/${orderCode}`)
    if (!details) {
      yield put(push(routes.NOT_FOUND()))
      return
    }

    if (details.id !== orderCode) {
      yield put(push(routes.ORDERS_VIEW({ orderId: details.id, selected: orderCode })))
      return
    }

    yield put(actions.receivedOrderDetails(details))

    if (details.source === DataSource.Studenten) {
      yield put(
        global.showWarning(
          'Default preview images are used until customer images are ready. It usually takes 1-3 days for them to update'
        )
      )
      return
    }

    if (details.products && details.products.length > 0) {
      const previews = details.products.filter((p) => p.preview).map((p) => p.preview) as ProductPreview[]
      yield call(fetchPreviews, details.source, previews)
    }

    yield put(sbsActions.receivedSbs({ error: 'SBS temporary unavailable' }))
  } catch (e) {
    if (e.response) {
      if (e.response.status === 404) {
        yield put(push(routes.NOT_FOUND()))
        return
      }
      if (e.response.status === 410) {
        yield put(push(routes.NOT_FOUND()))
        yield put(global.showWarning(e.response.data.message))
        return
      }
    }

    yield put(push(routes.ERROR()))
    yield put(Errors.toErrorAction(e))
  } finally {
    yield put(global.hideSpinner())
  }
}

function* postOperation(action: PayloadAction<InternalOperationRequest>) {
  try {
    yield put(global.showSpinner())
    const response = yield call(ApiServiceInstance.post, 'orders/internal-operation', action.payload)

    // accepted
    if (response.status === 202) {
      yield put(global.showWarning(response.data))
      yield put(global.hideSpinner())
    } else {
      window.location.reload()
    }
  } catch (e) {
    const message = getErrorMessage(e)
    yield put(Errors.toErrorAction(e, message))
    yield put(global.hideSpinner())
  }
}

function* downloadInvoice(action: PayloadAction<InvoiceDownloadRequest>) {
  try {
    yield put(global.showSpinner())

    const path = `orders/invoice/${action.payload.orderNumber}`
    const url = yield call(ApiServiceInstance.get, path)
    window.open(url)
  } catch (e) {
    if (!action.payload.isPaid) {
      yield put(global.showWarning('An invoice is available for paid orders only.'))
      return
    }

    if (e.response && e.response.status === 404) {
      yield put(
        global.showWarning(
          'The invoice is not available. ' +
            `Please contact #payments-care on Slack with the order number (${action.payload.orderNumber})`
        )
      )
      return
    }

    yield put(Errors.toErrorAction(e))
  } finally {
    yield put(global.hideSpinner())
  }
}

function* fetchOrderHistoryDetails(action: PayloadAction<string>) {
  try {
    const details = yield call(ApiServiceInstance.get, `orders/${action.payload}?detailed=false`)
    yield put(actions.receivedOrderHistoryDetails(details))

    const previewsResponse = yield requestPreviews(details)
    if (previewsResponse) {
      yield put(actions.receivedOrderHistoryDetailsPreviews(previewsResponse))
    }
  } catch (e) {
    if (e.response) {
      if (e.response.status === 410) {
        yield put(global.showWarning(e.response.data.message))
        return
      }
    }
    yield put(Errors.toErrorAction(e))
  }
}

function* requestPreviews(order: OrderListItemModel) {
  const previewIds = _.map(order.products)
    .filter((x) => !!x)
    .map((x) => (x.preview && x.preview.id) || '')
    .filter((x) => !!x)

  const previews = yield requestPreviewsByIds(order.source, previewIds)

  if (!previews) {
    return undefined
  }

  return { orderCode: order.id, previews: previews }
}

function* requestPreviewsByIds(dataSource: DataSource, previewIds: string[]) {
  if (previewIds.length > 0) {
    const query = {
      dataSource,
      previewIds,
    }

    return yield call(ApiServiceInstance.get, `product/preview?${queryString.stringify(query, { arrayFormat: 'index' })}`)
  }
}

function* fetchDiscountInfo(action: PayloadAction<string>) {
  try {
    const response = yield call(ApiServiceInstance.get, `discounts/${encodeURIComponent(action.payload)}`)

    yield put(actions.receivedDiscountView(response))
  } catch (e) {
    yield put(actions.receivedDiscountViewError('Discount request failed'))
  }
}

function* changeAddress(action: PayloadAction<ChangeAddressRequest>) {
  try {
    yield put(global.showSpinner())
    const body = {
      orderDeliveryAddress: action.payload.newAddress,
      addressAsString: action.payload.addressAsString,
      appVersion: action.payload.appVersion,
      vendorId: action.payload.vendorId,
    }
    const response = yield call(ApiServiceInstance.put, `orders/${action.payload.orderCode}/deliveryAddress`, body)

    // accepted
    if (response.status === 202) {
      yield put(global.showWarning(response.data))
      yield put(global.hideSpinner())
    } else {
      window.location.reload()
    }
  } catch (e) {
    const message = getErrorMessage(e)
    yield put(Errors.toErrorAction(e, message))
    yield put(global.hideSpinner())
  }
}

export default function* root() {
  yield all([
    yield takeLatest(actions.requestOrderDetails, fetchOrderDetails),
    yield takeLatest(actions.submitOperation, postOperation),
    yield takeEvery(actions.requestDownloadInvoice, downloadInvoice),
    yield takeEvery(actions.requestOrderHistoryDetails, fetchOrderHistoryDetails),
    yield takeLatest(actions.requestDiscountView, fetchDiscountInfo),
    yield takeLatest(actions.changeAddress, changeAddress),
  ])
}
