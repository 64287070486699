import { PayloadAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import React, { ReactNode } from 'react'
import { ErrorPayload } from '../actions/globalActions'
import * as global from '../actions/globalActions'

interface ValidationErrors {
  [key: string]: string[]
}

class ErrorMessageService {
  noViewerRights(): PayloadAction<ErrorPayload, string> {
    return this.toErrorAction(
      undefined,
      <span>
        Use of the Customer Back Office is limited to employees with an access permission.
        <br />
        &nbsp;&nbsp;- If you are a CC agent and need access, contact your Team manager immediately.
        <br />
        &nbsp;&nbsp;- If you are not a CC agent, please contact #care-tech-support via Slack with a clear reason on why you need
        access. Access will be granted on a case-by-case basis.
      </span>
    )
  }

  toErrorAction(
    e?: AxiosError,
    defaultError: ReactNode = 'An error has occurred. Please try again. If the error persists, please contact #care-tech-support via Slack.'
  ): PayloadAction<ErrorPayload, string> {
    if (e?.response?.data?.errors) {
      const errors = e.response.data.errors as ValidationErrors;
      const errorMessages = Object.entries(errors)
        .map(([field, messages]) => `${field}: ${messages.join(', ')}`)
        .join('\n');
      return global.showError({
        httpResponse: e.response,
        defaultMessage: errorMessages
      });
    }

    return global.showError({
      httpResponse: e?.response,
      defaultMessage: defaultError,
    });
  }
}

export const Errors = new ErrorMessageService()
