import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { Container, Row } from 'reactstrap'
import * as orderActions from '../actions/orderDetailsActions'
import * as actions from '../actions/productViewActions'
import {
  codes,
  GlobalConfiguration,
  LogMessage,
  OrderDetails,
  OrderEvents,
  Product,
  ProductContentLog,
  RestartFinalizerRequest,
  RestoreFromColdStorageRequest,
} from '../api'
import RestartFinalizerForm from '../components/forms/RestartFinalizerForm'
import ActionLink, { ActionLinkSettings } from '../components/generic/ActionLink'
import Grid, { GridColumn } from '../components/generic/Grid'
import LazyContainer from '../components/generic/LazyContainer'
import ProductCard from '../components/generic/ProductCard'
import ProductionOrderProductCard from '../components/generic/ProductionOrderProductCard'
import { BadgeSetting, CommonProps } from '../models'
import { AppStore, ProductViewStore } from '../reducers/states'
import routes from '../route/routes'
import { LogApiInstance } from '../services/LogApiService'
import { OrderDetailsServiceInstance as Service } from '../services/OrderDetailsService'
import { selectOnClick, toDateTimeNewWithSeconds } from '../utils'
import './ProductView.scss'

interface State {
  timeoutId: number
}

interface Props extends CommonProps, ProductViewStore {
  orderDetails?: OrderDetails
  configuration: GlobalConfiguration
  isLoading: boolean
}

class ProductView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    // update logs every 15 seconds
    const continuousRefreshTimeoutId = window.setInterval(() => {
      props.dispatch(actions.requestProductViewPartial(props.match.params.contentId))
    }, 15000)

    this.state = { timeoutId: continuousRefreshTimeoutId }

    this.requestRestartFinalizer = this.requestRestartFinalizer.bind(this)
    this.downloadFile = this.downloadFile.bind(this)
    this.restoreFile = this.restoreFile.bind(this)
  }

  componentDidMount() {
    const params = this.props.match.params

    // already loaded
    if (this.props.data && this.props.data.id === params.contentId) {
      return
    }

    this.props.dispatch(actions.requestProductView(params))

    if (params.orderId) {
      if (!this.props.orderDetails || params.orderId !== this.props.orderDetails.id) {
        this.props.dispatch(orderActions.requestOrderDetails(params.orderId))
      }
    }
  }

  componentWillUnmount() {
    window.clearInterval(this.state.timeoutId)
  }

  requestRestartFinalizer(request: RestartFinalizerRequest) {
    this.props.dispatch(actions.requestRestartFinalizer(request))
  }

  downloadFile(url: string, logMessage?: string) {
    const params = this.props.match.params

    LogApiInstance.info(new LogMessage({ message: logMessage, order: params.orderId || params.contentId }, codes.fileDownloaded))
    window.open(url, '_blank')
  }

  restoreFile(storageId: string) {
    const params = this.props.match.params

    const request: RestoreFromColdStorageRequest = {
      storageId: storageId,
      orderCode: params.orderId,
      productCode: params.productId,
      productContentId: params.contentId,
    }
    this.props.dispatch(actions.requestRestoreFromColdStorage(request))
  }

  renderBody() {
    const data = this.props.data
    const matchParams = this.props.match.params

    if (!data) {
      return
    }

    const columns: GridColumn<ProductContentLog>[] = [
      { title: 'Date', value: (item) => toDateTimeNewWithSeconds(item.date) },
      { title: 'Source', value: (item) => item.source },
      { title: 'Status', value: (item) => item.status },
      { title: 'Message', value: (item) => item.message }
    ]

    const sortedLogs = _.orderBy(this.props.logs, [(i: ProductContentLog) => i.date], ['desc'])
    const matchedProduct = this.matchedProduct()


    let restartFinalizerAvailable = true
    let restartFinalizerWarning = ''

    if (!data.albxStorageId || !data.albxDownloadUrl) {
      restartFinalizerAvailable = false
      restartFinalizerWarning = "Restart Finalizer is not available for client editors' products."
    }

    return (
      <Container fluid>
        <Row>
          <ActionLink
            text="< back"
            className="mr-4"
            route={this.props.orderDetails ? routes.ORDERS_VIEW({ orderId: this.props.orderDetails.id }) : routes.SEARCH()}
          />
        </Row>
        <Row className="mt-3">
          <h3>Product content</h3>
          <div className="bo-container">
            <table className="bo-grid-vertical">
              <tbody>
                <tr>
                  <th>ID</th>
                  <td>{data.id}</td>
                </tr>
                <tr>
                  <th>ALBX</th>
                  <td>
                    {this.renderFileInfo(data.albxStorageId, data.albxDownloadUrl, data.albxIsInColdStorage, 'ALBX downloaded')}
                  </td>
                </tr>
                <tr>
                  <th>PDF</th>
                  <td>
                    {this.renderFileInfo(data.pdfStorageId, data.pdfDownloadUrl, data.pdfIsInColdStorage, 'PDF downloaded')}
                  </td>
                </tr>
                <tr>
                  <th>Image Archive</th>
                  <td>
                    {this.renderFileInfo(data.imageArchiveStorageId, data.imageArchiveDownloadUrl, data.imageArchiveIsInColdStorage, 'Image Archive downloaded')}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Row>
        <Row className="mt-3">
          <h3>Restart finalizer job</h3>
          <div className="bo-container restart-finalizer">
            {restartFinalizerAvailable ? (
              <RestartFinalizerForm
                input={{
                  appVersion: this.props.configuration.version,
                  orderCode: matchParams.orderId,
                  productCode: matchParams.productId,
                  contentId: matchParams.contentId,
                  vendor: this.props.orderDetails ? this.props.orderDetails.vendor : undefined,
                  editor: matchedProduct?.editor?.name,
                }}
                onSubmit={this.requestRestartFinalizer}
              />
            ) : (
              <i>{restartFinalizerWarning}</i>
            )}
          </div>
        </Row>
        <Row className="mt-3">
          <h3>Status history</h3>
          <Grid columns={columns} data={sortedLogs} className="finalizer-logs" disableCopyOnClick={true} />
        </Row>

        {this.renderOrderDetails()}
      </Container>
    )
  }

  renderFileInfo(storageId?: string, href?: string, coldStorage?: boolean, logMessage?: string) {
    if (!storageId) {
      return <i>N/A</i>
    }

    return (
      <span>
        <span onClick={selectOnClick} className="mr-2">
          {storageId}
        </span>
        {this.renderDownloadLink(storageId, href, coldStorage, logMessage)}
      </span>
    )
  }

  renderDownloadLink(storageId: string, href?: string, coldStorage?: boolean, logMessage?: string) {
    if (coldStorage) {
      if (this.props.restoringFiles.some((x) => x === storageId)) {
        return <i>Restoring</i>
      } else {
        return <ActionLink onClick={() => this.restoreFile(storageId)} text="Restore" />
      }
    }

    if (href) {
      return <ActionLink onClick={() => this.downloadFile(href, logMessage)} className="external" />
    }

    return <i>Not available</i>
  }

  renderOrderDetails() {
    if (!this.props.orderDetails) {
      return
    }

    const data = this.props.orderDetails

    const matchedProduct = this.matchedProduct()

    if (!matchedProduct) {
      LogApiInstance.error(
        new LogMessage(
          {
            message: `Product ${this.props.match.params.productId} absent in order details`,
            orderDetails: data,
          },
          codes.badState
        )
      )

      return
    }

    const parentBadgeSettings = Service.resolveOrderStatusBadgeSettings(data, false)

    return (
      <React.Fragment>
        <Row className="mt-3">
          <h3 className="mb-0">Product details</h3>
        </Row>
        {this.renderProductCard(matchedProduct, data, parentBadgeSettings)}
      </React.Fragment>
    )
  }

  renderProductCard(product: Product, order: OrderDetails, parentStatus: BadgeSetting) {
    const previewSrc = Service.resolveProductPreviewUrl(product)
    const productionOrderProduct = Service.resolveProductionOrderProduct(product, order.productionOrders)

    const actions: ActionLinkSettings[] = [
      {
        text: 'View order details',
        route: routes.ORDERS_VIEW({
          orderId: this.props.match.params.orderId,
        }),
      },
    ]

    const relatedNotes = Service.filterNotes(order.notes, product.id)
    const events = Service.resolveProductEvents(order.orderEvents, product.id)
    const resetCounter = Service.resolveOrderEventsByType(events || [], OrderEvents.Reprocess).length

    return (
      productionOrderProduct !== undefined ?
        <ProductionOrderProductCard
          key={product.id}
          product={product}
          productionOrderProduct={productionOrderProduct}
          productEvents={events}
          vendor={order.vendor}
          actions={actions}
          previewSrc={previewSrc}
          parentStatus={parentStatus}
          notesCount={relatedNotes.length}
          resetCounter={resetCounter}
          highlighted={false}
        />
        :
        <div className="bo-container">
          <ProductCard
            key={product.id}
            product={product}
            productEvents={events}
            vendor={order.vendor}
            actions={actions}
            previewSrc={previewSrc}
            parentStatus={parentStatus}
            notesCount={relatedNotes.length}
            resetCounter={resetCounter}
            hasSbs={false} // TODO: fix me
            highlighted={false}
          />
        </div>
    )
  }

  render() {
    return <LazyContainer isLoading={this.props.isLoading}>{this.renderBody()}</LazyContainer>
  }

  matchedProduct() {
    const orderDetails = this.props.orderDetails

    if (orderDetails) {
      const [matchedProduct] = orderDetails.products.filter((p) => p.id === this.props.match.params.productId)

      if (matchedProduct) {
        return matchedProduct
      }
    }

    return undefined
  }
}

function mapStateToProps(state: AppStore) {
  return {
    ...state.productView,
    orderDetails: state.orderDetails.data,
    configuration: state.global.configuration,
    isLoading: state.global.loading,
  } as Props
}

export default connect(mapStateToProps)(ProductView)
