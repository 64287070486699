import _ from 'lodash'
import React from 'react'
import { codes, LogMessage, TraceWithProducts } from '../../api'
import * as OrderTypes from '../../api/order'
import { LogApiInstance } from '../../services/LogApiService'
import { OrderDetailsServiceInstance as Service } from '../../services/OrderDetailsService'
import { nl2br, selectOnClick, toDate, toDateTimeNew } from '../../utils'
import ActionLink from '../generic/ActionLink'
import SidePanel, { SidePanelSettings } from './SidePanel'

export interface DeliveryInfoPanelProps extends SidePanelSettings {
  data: OrderTypes.OrderDetails
}

const NOT_AVAILABLE_TEXT = 'N/A'
const NOT_AVAILABLE_NODE = <span className="secondary-font">{NOT_AVAILABLE_TEXT}</span>

export default class DeliveryInfoPanel extends React.Component<DeliveryInfoPanelProps> {
  render() {
    const deliveryInfo = this.props.data.deliveryInfo || []
    const delayedProducts = Service.getDelayedProductCodes(this.props.data.orderEvents)

    const uniqueDelayedProducts = this.filterOutDelayedProductsPlannedForMoreThanOneShipment(deliveryInfo, delayedProducts)
    const orderCreationYear = (this.props.data.createdDateTime ? new Date(this.props.data.createdDateTime) : null)?.getFullYear() ?? 0;

    return (
      <SidePanel {...this.props}>
        <table className="bo-grid-vertical wide">
          <tbody>
            {deliveryInfo.map((s, i) => (
              <React.Fragment key={i}>
                {
                  s.productionOrderId ? (
                    <tr>
                      <th>Production order</th>
                      <td>{s.productionOrderId}</td>
                    </tr>
                  ) : (
                    <tr>
                      <th>{orderCreationYear < 2025 ? 'Historical order' : 'Pending order'}</th>
                    </tr>
                  )
                }
                <tr>
                  <th>Distributor</th>
                  <td onClick={selectOnClick}>{Service.resolveCarrierName(s.distributor) || NOT_AVAILABLE_NODE}</td>
                </tr>
                <tr>
                  <th>Tracking id</th>
                  <td>
                    {s.trackingId ? (
                      <span>
                        <span onClick={selectOnClick}>{s.trackingId}</span>
                        <ActionLink className="ml-1" href={`${s.trackingUrl}`} />
                      </span>
                    ) : (
                      NOT_AVAILABLE_NODE
                    )}
                  </td>
                </tr>
                {this.renderProductsRow(s)}
                <tr>
                  <th>Scanned for shipment</th>
                  <td>{toDateTimeNew(s.scannedDate) || NOT_AVAILABLE_NODE}</td>
                </tr>
                <tr>
                  <th>Estimated delivery</th>
                  <td>
                    <span className={s.scannedDate ? 'secondary-font' : ''}>{toDate(s.estimatedDate) || NOT_AVAILABLE_NODE}</span>
                    <br />
                    {uniqueDelayedProducts != undefined && uniqueDelayedProducts.some((p) => s.products != undefined && s.products.some((sp) => sp != undefined && sp.productCode != undefined && sp.productCode === p)) && (
                      <b> Shipment is delayed</b>
                    )}
                    {/*<br />
                    {this.renderCampaignInfo(s)}*/}
                  </td>
                </tr>
                <tr>
                  <th>Delivery Type</th>
                  <td onClick={selectOnClick}>{s.deliveryType || NOT_AVAILABLE_NODE}</td>
                </tr>
                <tr>
                  <th>Delivery Method</th>
                  <td onClick={selectOnClick}>{s.deliveryMethod || NOT_AVAILABLE_NODE}</td>
                </tr>
                {this.renderHorizontalLine()}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </SidePanel>
    )
  }

  filterOutDelayedProductsPlannedForMoreThanOneShipment(deliveryInfo: TraceWithProducts[], delayedProducts: string[]): string[] {    
    return delayedProducts.filter((p) => {
      const howManyShipmentsAffected = deliveryInfo.filter((di) => di.products != undefined && di.products.some((dip) => dip != undefined && dip.productCode === p)).length
      switch (howManyShipmentsAffected) {
        case 1:
          return true
        case 0: {
          const alertMessage = `${this.props.data.id}: scheduled shipment for product "${p}" not found`
          console.error(alertMessage)
          LogApiInstance.error(new LogMessage(alertMessage, codes.badState))
          return false
        }
        default: {
          const alertMessage = `${this.props.data.id}: there are several scheduled shipments for delayed product`
          console.warn(alertMessage)
          LogApiInstance.warn(new LogMessage(alertMessage, codes.ambiguousDelayEvent))
          return false
        }
      }
    })
  }

  renderCampaignInfo(shipment: TraceWithProducts) {
    let promised = false
    let promiseBroken = false
    let campaignName: string | undefined = undefined
    for (let i = 0; i < shipment.products.length; i++) {
      const product = shipment.products[i]
      const events = Service.resolveProductEvents(this.props.data.orderEvents, product.productCode)
      const campaign = Service.resolvePromisedCampaign(events)
      if (!campaign) {
        continue
      }
      promised = true
      promiseBroken = promiseBroken || campaign.isBrokenPromise
      campaignName = campaignName || campaign.campaignName
    }

    if (promiseBroken) {
      return <b className="danger-color">Not in time for {campaignName || NOT_AVAILABLE_TEXT}</b>
    }

    if (promised) {
      return <b className="success-color">Still on time for {campaignName || NOT_AVAILABLE_TEXT}</b>
    }

    return null
  }

  renderHorizontalLine() {
    return (
      <tr className="delimiter">
        <th colSpan={2}>
          <hr />
        </th>
      </tr>
    )
  }

  renderProductsRow(shipment: TraceWithProducts) {
    return (
      <tr>
        <th>Products</th>
        <td>
          {nl2br(
            _.join(
              shipment.products?.map((p) => `${p.productCode} (${p.qty})`),
              '\n'
            )
          )}
        </td>
      </tr>
    )
  }
}
