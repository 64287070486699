import React from 'react'
import { Form, FormGroup, Input } from 'reactstrap'
import { Field, InjectedFormProps, reduxForm, WrappedFieldProps } from 'redux-form'
import { ShowByScanForm, ShowByScanNames, ShowByScanType } from '../../api'
import { nameof, validators } from '../../utils'
import ActionLink from '../generic/ActionLink'
import Textarea from './Textarea'
import ValidationWrapper, { CustomComponent } from './ValidationWrapper'

const AlertTypeField = (props: WrappedFieldProps & CustomComponent) => {
  const hasError = !!props.meta.error && !!props.meta.touched
  const option = (type: ShowByScanType) => <option value={type}>{ShowByScanNames[type]}</option>

  return (
    <ValidationWrapper {...props}>
      <Input type="select" {...props.input} className="form-control" invalid={hasError} disabled={props.disabled}>
        <option value="" disabled style={{ display: 'none' }}>
          {' -- select an option -- '}
        </option>
        {option(ShowByScanType.ChangeAddress)}
        {option(ShowByScanType.ExtraQuality)}
        {option(ShowByScanType.Courier)}
        {option(ShowByScanType.Other)}
      </Input>
    </ValidationWrapper>
  )
}

interface ShowByScanNoteFormProps {
  onSubmit(x: ShowByScanForm): void
  onCancel(): void
}

function ShowByScanNoteForm(props: ShowByScanNoteFormProps & InjectedFormProps<ShowByScanForm, ShowByScanNoteFormProps>) {
  const { error, handleSubmit, onSubmit, onCancel, submitting } = props

  const validatorsArray = [validators.required, validators.maxLength200]

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Field
        name={nameof<ShowByScanForm>('type')}
        component={AlertTypeField}
        label="Alert type"
        validate={[validators.required]}
      />
      <Field name={nameof<ShowByScanForm>('text')} component={Textarea} rows={5} label="Text" validate={validatorsArray} />
      {error && <strong>{error}</strong>}
      <FormGroup>
        <button type="submit" disabled={submitting}>
          Save
        </button>
        &nbsp;
        <ActionLink className="ml-3" text="Cancel" onClick={() => onCancel()} />
      </FormGroup>
    </Form>
  )
}

export const ShowByScanNote = reduxForm<ShowByScanForm, ShowByScanNoteFormProps>({ enableReinitialize: true })(ShowByScanNoteForm)
