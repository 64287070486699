import React from 'react'
import { Col, Row } from 'reactstrap'
import { InternalOperationRequest, OrderEvent, OrderEvents } from '../../api/order'
import { Product, ProductRequest } from '../../api/product'
import notesIcon from '../../assets/img/flags/notes.svg'
import resetIcon from '../../assets/img/flags/reset.svg'
import sbsIcon from '../../assets/img/flags/sbs.svg'
import { BadgeSetting, EditorDescription, TogglePanel } from '../../models'
import routes from '../../route/routes'
import { OrderDetailsServiceInstance as Service, PlantInfo } from '../../services/OrderDetailsService'
import { highlightText } from '../../utils'
import { ReuploadForm } from '../forms/ReuploadForm'
import ProductOptionsPanel from '../panels/ProductOptionsPanel'
import SidePanel from '../panels/SidePanel'
import WidgetPanel from '../panels/WidgetPanel'
import ActionLink, { ActionLinkSettings } from './ActionLink'
import ActionList from './ActionList'
import Badge from './Badge'
import CampaignIcon from './CampaignIcon'
import EditorIcon from './EditorIcon'
import Flag from './Flag'
import Grid, { GridColumn } from './Grid'
import LazyContainer from './LazyContainer'
import PlantIcon from './PlantIcon'

export interface ProductCardProps extends React.Props<any> {
  product: Product
  productEvents: OrderEvent[]
  vendor: string
  actions: ActionLinkSettings[]
  previewSrc: string
  optionsPanel?: TogglePanel
  reuploadPanel?: TogglePanel
  cancelReuploadPanel?: TogglePanel
  widgetPanel?: TogglePanel
  parentStatus: BadgeSetting
  onReuploadSubmit?(x: InternalOperationRequest): void
  onReuploadCancel?(x: ProductRequest): void
  highlighted: boolean
  notesCount: number
  hasSbs: boolean
  widgetPreviewUrl?: string
  resetCounter: number
  onCreateWidgetClick?(): void
  onDeleteWidgetClick?(): void
  onRecreateWidgetClick?(): void
  onDelayLinkClick?(): void
}

export default class ProductCard extends React.Component<ProductCardProps> {
  constructor(props: ProductCardProps) {
    super(props)

    this.cancelReuploadSubmit = this.cancelReuploadSubmit.bind(this)
    this.renderNotificationsIcons = this.renderNotificationsIcons.bind(this)
  }

  cancelReuploadSubmit() {
    if (this.props.onReuploadCancel) {
      this.props.onReuploadCancel({
        vendor: this.props.vendor,
        productCode: this.props.product.id,
      })
    }
  }

  render() {
    return (
      <React.Fragment key={this.props.product.id}>
        <Row className="m-0 w-100 pt-1">
          <div className="bo-container d-flex">
            <Col sm="12" md="4" lg="1" className="pl-0">
              <img src={this.props.previewSrc} width="100%" alt="preview" />
            </Col>
            <Col sm="12" md="8" lg="11">
              {this.renderProductInfo()}
            </Col>
          </div>
        </Row>
        <Row className="m-0 w-100">
          <ActionList actions={this.props.actions} />
          {this.props.optionsPanel && (
            <ProductOptionsPanel
              {...this.props.optionsPanel}
              product={this.props.product}
            />
          )}
          {this.props.reuploadPanel && this.props.onReuploadSubmit && (
            <SidePanel {...this.props.reuploadPanel}>
              <LazyContainer isLoading={this.props.reuploadPanel.isLoading}>
                <ReuploadForm
                  form={`${this.props.product.id}_reupload`}
                  initialValues={{
                    products: [
                      {
                        id: this.props.product.id,
                        quantity: this.props.product.quantity,
                        value: this.props.product.quantity,
                      },
                    ],
                  }}
                  onSubmit={this.props.onReuploadSubmit}
                />
              </LazyContainer>
            </SidePanel>
          )}
          {this.props.cancelReuploadPanel && (
            <SidePanel {...this.props.cancelReuploadPanel}>
              <LazyContainer isLoading={this.props.cancelReuploadPanel.isLoading}>
                <button onClick={this.cancelReuploadSubmit}>Submit</button>
              </LazyContainer>
            </SidePanel>
          )}
          {this.renderWidgetPanel()}
        </Row>
      </React.Fragment>
    )
  }

  renderWidgetPanel() {
    if (
      !this.props.widgetPanel ||
      !this.props.onCreateWidgetClick ||
      !this.props.onDeleteWidgetClick ||
      !this.props.onRecreateWidgetClick
    ) {
      return
    }
    return (
      <WidgetPanel
        {...this.props.widgetPanel}
        product={this.props.product}
        widgetPreviewUrl={this.props.widgetPreviewUrl}
        onCreateClick={this.props.onCreateWidgetClick}
        onDeleteClick={this.props.onDeleteWidgetClick}
        onRecreateClick={this.props.onRecreateWidgetClick}
      />
    )
  }

  renderNotificationsIcons(product: any) {
    return (
      <>
        {product.promisedCampaign && !product.promisedCampaign.unpromised && <CampaignIcon campaign={product.promisedCampaign} productId={product.id} />}
        {!!this.props.notesCount && <Flag icon={notesIcon} title={`Notes (${this.props.notesCount})`} />}
        {this.props.hasSbs && <Flag icon={sbsIcon} title="Has SBS" />}
        {product.reuploadState && <Flag icon={product.reuploadState.image} title={product.reuploadState.title} />}
        {!!this.props.resetCounter && <Flag icon={resetIcon} title={`Reset product (x${this.props.resetCounter})`} />}
      </>
    )
  }

  renderProductInfo() {
    const infoLeft = {
      name: Service.resolveProductDescriptionByType(this.props.product.productId),
      code: this.props.product.id,
    }

    const infoRight = {
      id: this.props.product.id,
      editor: Service.resolveProductEditor(this.props.product.editor),
      statuses: Service.resolveProductStatuses(this.props.product, this.props.parentStatus),
      plants: Service.getPlantsFromOrderEvents(this.props.productEvents, this.props.product.id),
      promisedCampaign: Service.resolvePromisedCampaign(this.props.productEvents),
      isDelayed: Service.isDelayed(this.props.productEvents),
      reuploadState: Service.resolveReuploadState(this.props.productEvents),
    }

    const productNameFunc = (p: any) => {
      const getNameFunc = function () {
        if (!p.name) {
          return <i>Unknown product</i>
        }

        return `${p.name} ${p.isLayflat ? ' | layflat' : ''}`
      }

      const [reuploadedEventOld] = this.props.productEvents.filter((e) => e.event === OrderEvents.ReuploadedOriginal)
      const [reuploadedEventNew] = this.props.productEvents.filter((e) => e.event === OrderEvents.ReuploadedNew)

      return (
        <div>
          <div>{getNameFunc()}</div>
          {reuploadedEventOld && (
            <div className="mt-2">
              <ActionLink
                className="attention normal-font"
                route={routes.ORDERS_VIEW({ orderId: reuploadedEventOld.comment })}
                text={`Reuploaded as ${reuploadedEventOld.comment}`}
              />
            </div>
          )}
          {reuploadedEventNew && (
            <div className="mt-2">
              <ActionLink
                className="attention normal-font"
                route={routes.ORDERS_VIEW({ orderId: reuploadedEventNew.comment })}
                text={`Reupload of ${reuploadedEventNew.comment}`}
              />
            </div>
          )}
        </div>
      )
    }

    const columnsLeft: GridColumn<any>[] = [
      { title: 'Product number', value: (p) => highlightText(p.code, this.props.highlighted) },
      { title: this.props.product.title ? `“${this.props.product.title}”` : '', value: productNameFunc },
    ]

    const columnsRight: GridColumn<any>[] = [
      {
        title: 'Notifications',
        tdClass: 'text-center d-flex justify-content-between',
        value: this.renderNotificationsIcons,
      },
      {
        title: 'Plant',
        tdClass: 'text-center',
        value: (p) => {
          const plants: PlantInfo[] = p.plants
          const [plant] = plants

          const elementId = `plant_tooltip_${p.id}`
          return <PlantIcon elementId={elementId} plant={plant.plant} displayName={plant.displayName} />
        },
      },
      {
        title: 'Editor',
        tdClass: 'text-center',
        value: (p) => {
          const editor: EditorDescription = p.editor
          const elementId = `editor_tooltip_${p.id}`

          return <EditorIcon description={editor} elementId={elementId} />
        },
      },
      {
        title: `Status & quantity (${this.props.product.quantity})`,
        value: (p) => (
          <div>
            {p.statuses &&
              p.statuses.map((s: BadgeSetting, i: number) => (
                <div key={i}>
                  <Badge setting={s} className="fixed-size" />
                </div>
              ))}
            {p.isDelayed && (
              <ActionLink className="attention normal-font" onClick={this.props.onDelayLinkClick} text="Shipment is delayed" />
            )}
          </div>
        ),
      },
    ]

    return (
      <div className="d-flex justify-content-between">
        <div>
          <Grid columns={columnsLeft} data={[infoLeft]} className="embedded" />
        </div>
        <div>
          <Grid
            columns={columnsRight}
            data={[infoRight]}
            className="all-eq last-column-status embedded"
            disableCopyOnClick={true}
          />
        </div>
      </div>
    )
  }
}
