import React from 'react'
import { connect } from 'react-redux'
import * as bulkOperationsActions from '../actions/bulkOperationsActions'
import { ResetReason } from '../api'
import BulkCommentPanel from '../components/panels/BulkCommentPanel'
import BulkRefinalizePanel from '../components/panels/BulkRefinalizePanel'
import BulkRedoPanel from '../components/panels/BulkRedoPanel'
import BulkCancelPanel from '../components/panels/BulkCancelPanel'
import BulkStatusPanel from '../components/panels/BulkStatusPanel'
import { CommonProps } from '../models'
import { AppStore, BulkOperationsViewStore } from '../reducers/states'
import './BulkOperationsView.scss'

interface Props extends CommonProps, BulkOperationsViewStore {
  message: string
  productCodes: string
  identifiers: string
  resetReasons: ResetReason[]
}

class BulkOperationsView extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
    this.handleTabChange = this.handleTabChange.bind(this)
  }

  handleTabChange(event: any) {
    const tabIndex = parseInt(event.target.value)
    this.props.dispatch(bulkOperationsActions.tabIndexChange(tabIndex))
  }

  render() {
    return (
      <div className="panel">
        <div className="bulk-container">
          <div className="tabset">
            <input
              type="radio"
              name="tabset"
              id="bulkNoteTab"
              aria-controls="marzen"
              value="0"
              onChange={this.handleTabChange}
              checked={this.props.tabIndex === 0}
            />
            <label htmlFor="bulkNoteTab">Bulk Comment</label>

            <input
              type="radio"
              name="tabset"
              id="bulkRedoTab"
              aria-controls="marzen"
              value="1"
              onChange={this.handleTabChange}
              checked={this.props.tabIndex === 1}
            />
            <label htmlFor="bulkRedoTab">Bulk Redo (PO)</label>

            <input
              type="radio"
              name="tabset"
              id="bulkRefinalizeTab"
              aria-controls="marzen"
              value="2"
              onChange={this.handleTabChange}
              checked={this.props.tabIndex === 2}
            />
            <label htmlFor="bulkRefinalizeTab">Bulk Refinalize</label>

            <input
              type="radio"
              name="tabset"
              id="bulkStatusTab"
              aria-controls="marzen"
              value="3"
              onChange={this.handleTabChange}
              checked={this.props.tabIndex === 3}
            />
            <label htmlFor="bulkStatusTab">Bulk Status (PO)</label>

            <input
              type="radio"
              name="tabset"
              id="bulkCancelTab"
              aria-controls="marzen"
              value="4"
              onChange={this.handleTabChange}
              checked={this.props.tabIndex === 4}
            />
            <label htmlFor="bulkCancelTab">Bulk Cancel (PO)</label>

            <div className="tab-panels">
              <section id="comment" className="tab-panel">
                {this.props.tabIndex === 0 && <BulkCommentPanel {...this.props}></BulkCommentPanel>}
              </section>
              <section id="redo" className="tab-panel">
                {this.props.tabIndex === 1 && <BulkRedoPanel {...this.props}></BulkRedoPanel>}
              </section>
              <section id="refinalize" className="tab-panel">
                {this.props.tabIndex === 2 && <BulkRefinalizePanel {...this.props}></BulkRefinalizePanel>}
              </section>
              <section id="status" className="tab-panel">
                {this.props.tabIndex === 3 && <BulkStatusPanel {...this.props}></BulkStatusPanel>}
              </section>
              <section id="cancel" className="tab-panel">
                {this.props.tabIndex === 4 && <BulkCancelPanel {...this.props}></BulkCancelPanel>}
              </section>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state: AppStore) {
  return { ...state.bulkOperationsView, resetReasons: state.global.configuration.resetReasons } as Props
}
export default connect(mapStateToProps)(BulkOperationsView)
