import { createAction } from '@reduxjs/toolkit'
import {
  ChangeAddressRequest,
  Discount,
  InternalOperationRequest,
  InvoiceDownloadRequest,
  OrderDetails,
  OrderListItemModel,
} from '../api'
import { PreviewResponseWrapper } from '../models'

export const requestOrderDetails = createAction<string>('ORDER_DETAILS_REQUEST')

export const receivedOrderDetails = createAction<OrderDetails>('ORDER_DETAILS_RECEIVED')

export const receivedOrderDetailsPreview = createAction<{ [key: string]: string }>('ORDER_DETAILS_PREVIEW_RECEIVED')

export const submitOperation = createAction<InternalOperationRequest>('ORDER_DETAILS_SUBMIT_OPERATION')

export const togglePanel = createAction<string>('ORDER_DETAILS_TOGGLE_PANEL')

export const requestDownloadInvoice = createAction<InvoiceDownloadRequest>('ORDER_DETAILS_DOWNLOAD_INVOICE_REQUEST')

export const togglePaymentLink = createAction('ORDER_DETAILS_TOGGLE_PAYMENT_LINK')

export const toggleNotesPanelMode = createAction<boolean | undefined>('ORDER_DETAILS_TOGGLE_NOTES_PANEL_MODE')

export const changeNotesFilter = createAction<string | undefined>('ORDER_DETAILS_CHANGE_NOTES_FILTER')

export const requestOrderHistoryDetails = createAction<string>('ORDER_HISTORY_REQUEST')

export const receivedOrderHistoryDetails = createAction<OrderListItemModel>('ORDER_HISTORY_RECEIVED')

export const receivedOrderHistoryDetailsPreviews = createAction<PreviewResponseWrapper>('ORDER_HISTORY_RECEIVED_PREVIEWS')

export const requestDiscountView = createAction<string | undefined>('ORDER_DETAILS_DISCOUNT_REQUEST')

export const receivedDiscountView = createAction<Discount>('ORDER_DETAILS_DISCOUNT_RECEIVED')

export const receivedDiscountViewError = createAction<string>('ORDER_DETAILS_DISCOUNT_RECEIVED_ERROR')

export const changeAddress = createAction<ChangeAddressRequest>('ORDER_DETAILS_CHANGE_ADDRESS')

export const toggleAllDetails = createAction('ORDER_VIEW_TOGGLE_ALL_DETAILS')

export const toggleCardDetails = createAction<number>('ORDER_VIEW_TOGGLE_CARD_DETAILS')
