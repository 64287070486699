import React, { useState } from 'react'
import { OrderValueRecord } from '../../services/RefundCalculatorService'
import { toDateTime } from '../../utils'
import closed from '../../assets/img/closed.svg'
import open from '../../assets/img/open.svg'
import './CreditNotesTable.scss'

interface Props {
    remainingValueRecord: OrderValueRecord
    historyRefunds: OrderValueRecord[]
    refundInProgress?: OrderValueRecord
}

const CollapsableCreditNotesTable: React.FC<Props> = ({
    remainingValueRecord,
    historyRefunds,
    refundInProgress
}) => {
    const [showTable, setShowTable] = useState(false)
    const [showHistory, setShowHistory] = useState(false)

    // Get all unique product codes
    const allProducts = new Set<string>()
    remainingValueRecord.productValueRecords.forEach(p => allProducts.add(p.productCode))
    historyRefunds.forEach(r => r.productValueRecords.forEach(p => allProducts.add(p.productCode)))
    if (refundInProgress) {
        refundInProgress.productValueRecords.forEach(p => allProducts.add(p.productCode))
    }

    const productCodes = Array.from(allProducts).sort()

    const calculateTotal = (record: OrderValueRecord): number => {
        const productsTotal = record.productValueRecords.reduce((sum, p) => sum + p.value, 0)
        return productsTotal + (record.shippingCostValue ?? 0)
    }

    const findProductValue = (record: OrderValueRecord, productCode: string): number => {
        const product = record.productValueRecords.find(p => p.productCode === productCode)
        return product?.value ?? 0
    }

    const formatCurrency = (value: number): string => {
        return (Math.round(value * 100) / 100).toFixed(2)
    }

    return (
        <div>
            <span className='d-flex'>
                <span className="d-flex" onClick={() => setShowTable(!showTable)}>
                    <img src={showTable ? open : closed} width="20" height="20" alt="show refund summary table" />
                    <h3>SUMMARY</h3>
                </span>
                {historyRefunds.length > 0 && <span
                    className="d-flex ml-3"
                    onClick={() => setShowHistory(!showHistory)}
                >
                    <img src={(showTable && showHistory) ? open : closed} width="20" height="20" alt="show refund history in table" />
                    <h3>HISTORY</h3>
                </span>}
            </span>
            {showTable && (
                <div className="refund-table-container">
                    <table className="refund-table">
                        <thead>
                            <tr>
                                <th>Product</th>
                                <th className="remaining">
                                    Remaining
                                    <div className="date">{toDateTime(remainingValueRecord.date)}</div>
                                </th>
                                {showHistory && historyRefunds.map(refund => (
                                    <th key={refund.date?.toString()} className="history">
                                        {refund.reason}
                                        <div className="date">{toDateTime(refund.date)}</div>
                                        {refund.status}
                                    </th>
                                ))}
                                {refundInProgress && (
                                    <th className="in-progress">
                                        In Progress
                                        <div className="date">{toDateTime(refundInProgress.date)}</div>
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {productCodes.map(code => (
                                <tr key={code}>
                                    <td>{code}</td>
                                    <td>{formatCurrency(findProductValue(remainingValueRecord, code))}</td>
                                    {showHistory && historyRefunds.map(refund => (
                                        <td key={refund.date?.toString()}>
                                            {formatCurrency(findProductValue(refund, code))}
                                        </td>
                                    ))}
                                    {refundInProgress && (
                                        <td>{formatCurrency(findProductValue(refundInProgress, code))}</td>
                                    )}
                                </tr>
                            ))}
                            <tr>
                                <td>Shipping Costs</td>
                                <td>{formatCurrency(remainingValueRecord.shippingCostValue)}</td>
                                {showHistory && historyRefunds.map(refund => (
                                    <td key={refund.date?.toString()}>
                                        {formatCurrency(refund.shippingCostValue)}
                                    </td>
                                ))}
                                {refundInProgress && (
                                    <td>{formatCurrency(refundInProgress.shippingCostValue)}</td>
                                )}
                            </tr>
                            <tr className="total-row">
                                <td><strong>Total</strong></td>
                                <td><strong>{formatCurrency(calculateTotal(remainingValueRecord))}</strong></td>
                                {showHistory && historyRefunds.map(refund => (
                                    <td key={refund.date?.toString()}>
                                        <strong>{formatCurrency(calculateTotal(refund))}</strong>
                                    </td>
                                ))}
                                {refundInProgress && (
                                    <td><strong>{formatCurrency(calculateTotal(refundInProgress))}</strong></td>
                                )}
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    )
}

export default CollapsableCreditNotesTable