import React from 'react'
import { Alert } from 'reactstrap'
import { OrderDetailsServiceInstance as Service } from '../../services/OrderDetailsService'
import { isNowInRange, toDateTimeNew } from '../../utils'
import { Discount } from './../../api/order'
import { nl2br, regexpReplacer } from './../../utils/htmlHelpers'
import { DiscountUsage } from './DiscountUsage'

enum DiscountUsageTypes {
  Multi = 'Multi',
  OncePerCustomer = 'OncePerCustomer',
  SingleUse = 'SingleUse',
}

const DiscountUsageTypeNames: { [x: string]: string | undefined } = {
  Multi: 'Multiple use',
  OncePerCustomer: 'Once per Customer',
  SingleUse: 'Single use',
}

interface DiscountViewProps {
  error?: string
  data?: Discount
  discountCode?: string
  allowCodeRegeneration?: boolean
}

const papRegex = /(PAP_\d{3}(_COVER)?)/gi

const papToList = (str: string | undefined) => {
  return nl2br(str, (line, index) => {
    if (!line) {
      return ''
    } else if (!line.match(papRegex)) {
      return line
    } else {
      return regexpReplacer(line, papRegex, (innerLine, innerIndex) => {
        if (!innerLine || innerLine === ', ' || innerLine === '_COVER') {
          return ''
        }
        // special case for old discounts where strings before pap list are not \n terminated
        if (!innerLine.startsWith('PAP')) {
          return innerLine
        }
        return <li key={`${index}-${innerIndex}`}>{Service.resolveProductDescription(innerLine)}</li>
      })
    }
  })
}

export const DiscountView = (props: DiscountViewProps) => {
  if (props.error) {
    return (
      <Alert color="danger" className="w-100 mb-0">
        {props.error}
      </Alert>
    )
  }

  const data = props.data

  if (!data) {
    return null
  }

  const isValid = isNowInRange(data.validFrom, data.validTo)

  return (
    <>
      <table className="bo-grid-vertical wide">
        <tbody>
          <tr>
            <th>Info</th>
            <td id="discount_info">{data.description}</td>
          </tr>
          <tr>
            <th>Valid</th>
            <td id="discount_valid">
              <span style={{ color: isValid ? '#07761b' : '#f57c23' }}>{`${toDateTimeNew(data.validFrom)} - ${toDateTimeNew(
                data.validTo
              )}`}</span>
            </td>
          </tr>
          <tr>
            <th>Type</th>
            <td id="discount_type">{data.layer}</td>
          </tr>
          <tr>
            <th>Usage</th>
            <td id="discount_usage">{data.usageType ? DiscountUsageTypeNames[data.usageType] || data.usageType : 'unknown'}</td>
          </tr>
          {data.usageType === DiscountUsageTypes.SingleUse && (
            <tr>
              <th>Used in</th>
              <td id="used_in_order_code">
                <DiscountUsage
                  discount={data}
                  discountCode={props.discountCode}
                  allowCodeRegeneration={props.allowCodeRegeneration && isValid}
                />
              </td>
            </tr>
          )}
          {data.createdDate && (
            <tr>
              <th>Created</th>
              <td id="discount_created">{toDateTimeNew(data.createdDate)}</td>
            </tr>
          )}
          {data.lastChangedDate && toDateTimeNew(data.lastChangedDate) !== toDateTimeNew(data.createdDate) && (
            <tr>
              <th>Changed</th>
              <td id="discount_changed">{toDateTimeNew(data.lastChangedDate)}</td>
            </tr>
          )}
          <tr>
            <th>Definition</th>
            <td id="discount_definition">{papToList(data.definition)}</td>
          </tr>
        </tbody>
      </table>
    </>
  )
}
