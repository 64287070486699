import _ from 'lodash'
import { ReactNode } from 'react'
import {
  ArticleTypeNames,
  codes,
  CoverColorNames,
  Editor,
  FrameColorNames,
  FulfillmentPlant,
  LogMessage,
  Note,
  OrderEvent,
  OrderEvents,
  OrderEventSourceType,
  OrderLineStatus,
  ProductionOrderProductStatus,
  PaperTypeNames,
  PrintSurfaceNames,
  Product,
  ShowByScanRecord,
  PassepartoutNames,
  BorderNames,
  ColorNames,
  MaterialNames,
  PhotoSideNames,
} from '../api'
import * as OrderTypes from '../api/order'
import { ProductionOrder, ProductionOrderProduct } from '../api/productionOrder'
import reuploadCancelledIcon from '../assets/img/flags/reupload-cancel.svg'
import reuploadPermittedIcon from '../assets/img/flags/reupload-permitted.svg'
import reuploadedIcon from '../assets/img/flags/reupload-used.svg'
import defaultImage from '../assets/img/placeholder-image.png'
import { BadgeSetting, OrderProductStatus } from '../models'
import { EditorDescription } from '../models'
import { toDate } from '../utils/dateTime'
import { LogApiInstance } from './LogApiService'

export const ProductTypeNames: { [id: string]: string } = {
  PAP_006: '(DISCONTINUED) Photobook Landscape Ringwire 20 x 15 cm',
  PAP_050: 'Voucher',
  PAP_090: '(DISCONTINUED) Photobook Landscape Softcover 15 x 10 cm',
  PAP_093: '(DISCONTINUED) Photobook Landscape Softcover 15 x 10 cm',
  PAP_109: 'Photobook Landscape Softcover M 20 x 15 cm',
  PAP_116: '(DISCONTINUED) Postcards',
  PAP_119: '(DISCONTINUED) Greeting Cards Landscape (Double)',
  PAP_120: '(DISCONTINUED) Greeting Cards Portrait (Double)',
  PAP_130_COVER: 'Photobook Landscape Hardcover M 20 x 15 cm',
  PAP_130: 'Photobook Landscape Hardcover M 20 x 15 cm',
  PAP_193: 'Square XL 30 x 30 cm (Padded Layflat)',
  PAP_194_COVER: 'Photobook Square Hardcover XL 30 x 30 cm',
  PAP_194: 'Photobook Square Hardcover XL 30 x 30 cm',
  PAP_201_COVER: 'Photobook Portrait Hardcover M 15 x 20 cm',
  PAP_201: 'Photobook Portrait Hardcover M 15 x 20 cm',
  PAP_202_COVER: 'Photobook Portrait Hardcover XL 27 x 36 cm',
  PAP_202: 'Photobook Portrait Hardcover XL 27 x 36 cm',
  PAP_203: 'Photobook Square Softcover S 10 x 10 cm',
  PAP_204: 'Photobook Square Softcover L 21 x 21 cm',
  PAP_205: 'Photobook Square Softcover M 14 x 14 cm',
  PAP_206: 'LayFlat Small 14x14 cm (LittleMoments)',
  PAP_290: '(DISCONTINUED) Photo Diary 12 x 15 cm',
  PAP_318: 'Landscape L 21 x 28 cm (Padded Layflat)',
  PAP_319: 'Landscape L 28 x 21 cm (Padded Layflat)',
  PAP_321: 'Landscape L 28 x 21 cm (Cutout)',
  PAP_323: 'Square L 21 x 21 cm (Padded Layflat)',
  PAP_324_COVER: 'Photobook Square Hardcover L 21 x 21 cm',
  PAP_324: 'Photobook Square Hardcover L 21 x 21 cm',
  PAP_328: 'Landscape XL 39 x 29 cm (Padded Layflat)',
  PAP_335: '(DISCONTINUED) School Diary 15 x 20 cm',
  PAP_347_COVER: 'Photobook Landscape Hardcover L 28 x 21 cm',
  PAP_347: 'Photobook Landscape Hardcover L 28 x 21 cm',
  PAP_348_COVER: 'Photobook Portrait Hardcover L 21 x 28 cm',
  PAP_348: 'Photobook Portrait Hardcover L 21 x 28 cm',
  PAP_349: 'Photobook Portrait Softcover L 21 x 28 cm',
  PAP_350: 'Photobook Landscape Softcover L 28 x 21 cm',
  PAP_354_COVER: 'Photobook Landscape Hardcover S 13 x 10 cm',
  PAP_354: 'Photobook Landscape Hardcover S 13 x 10 cm',
  PAP_355_COVER: 'Photobook Landscape Hardcover XL 39 x 29 cm',
  PAP_355: 'Photobook Landscape Hardcover XL 39 x 29 cm',
  PAP_360_COVER: 'Photobook Square Hardcover M 14 x 14 cm',
  PAP_360: 'Photobook Square Hardcover M 14 x 14 cm',
  PAP_376: '(DISCONTINUED) Greeting Cards Square (Double)',
  PAP_395: '(DISCONTINUED) Birthday Calendar Design 21 x 28 cm',
  PAP_397: '(DISCONTINUED) XL Photo Calendar 35 x 26,5 cm',
  PAP_398: '(DISCONTINUED) Year Calendar Landscape 28 x 21 cm',
  PAP_399: '(DISCONTINUED) Birthday Calendar 21 x 28 cm',
  PAP_401: '(DISCONTINUED) Year Calendar Portrait 21 x 28 cm',
  PAP_402: '(DISCONTINUED) Photobook Portrait LooseSheets 21 x 28 cm',
  PAP_403: 'Canvas 40 x 30 cm',
  PAP_404: 'Canvas 60 x 40 cm',
  PAP_405: 'Canvas 70 x 50 cm',
  PAP_408: 'Canvas 100 x 70 cm',
  PAP_409: 'Canvas 120 x 80 cm',
  PAP_410: 'Canvas 30 x 30 cm',
  PAP_411: 'Canvas 40 x 40 cm',
  PAP_412: 'Canvas 50 x 50 cm',
  PAP_413: 'Canvas 60 x 60 cm',
  PAP_414: 'Canvas 70 x 70 cm',
  PAP_415: 'Canvas 80 x 80 cm',
  PAP_416: 'Canvas 28 x 21 cm',
  PAP_417: 'Canvas 100 x 100 cm',
  PAP_418: 'Canvas 30 x 20 cm',
  PAP_419: 'Canvas 45 x 30 cm',
  PAP_420: 'Canvas 20 x 30 cm',
  PAP_421: 'Canvas 30 x 40 cm',
  PAP_422: 'Canvas 40 x 60 cm',
  PAP_423: 'Canvas 50 x 70 cm',
  PAP_424: 'Canvas 70 x 100 cm',
  PAP_425: 'Canvas 80 x 120 cm',
  PAP_426: 'Canvas 60 x 80 cm',
  PAP_427: 'Canvas 80 x 60 cm',
  PAP_428: 'Canvas 20 x 20 cm',
  PAP_429: 'Canvas 50 x 40 cm',
  PAP_430: 'Canvas 60 x 30 cm',
  PAP_431: 'Canvas 60 x 45 cm',
  PAP_432: 'Canvas 75 x 50 cm',
  PAP_433: 'Canvas 90 x 30 cm',
  PAP_434: 'Canvas 90 x 60 cm',
  PAP_435: 'Canvas 100 x 50 cm',
  PAP_436: 'Canvas 100 x 75 cm',
  PAP_437: 'Canvas 105 x 70 cm',
  PAP_438: 'Canvas 120 x 40 cm',
  PAP_439: 'Canvas 120 x 60 cm',
  PAP_440: 'Canvas 120 x 90 cm',
  PAP_441: 'Canvas 150 x 100 cm',
  PAP_442: 'Canvas 200 x 100 cm',
  PAP_443: 'Canvas 21 x 28 cm',
  PAP_444: 'Canvas 30 x 45 cm',
  PAP_445: 'Canvas 40 x 50 cm',
  PAP_446: 'Canvas 45 x 60 cm',
  PAP_447: 'Canvas 50 x 75 cm',
  PAP_448: 'Canvas 60 x 90 cm',
  PAP_449: 'Canvas 75 x 100 cm',
  PAP_450: 'Acrylic 40 x 30 cm',
  PAP_451: 'Acrylic 60 x 40 cm',
  PAP_452: 'Acrylic 70 x 50 cm',
  PAP_453: 'Acrylic 100 x 70 cm',
  PAP_454: 'Acrylic 30 x 20 cm',
  PAP_455: 'Acrylic 120 x 80 cm',
  PAP_456: 'Acrylic 80 x 60 cm',
  PAP_457: 'Acrylic 28 x 21 cm',
  PAP_458: 'Acrylic 45 x 30 cm',
  PAP_459: 'Acrylic 50 x 40 cm',
  PAP_460: 'Acrylic 60 x 30 cm',
  PAP_461: 'Acrylic 60 x 45 cm',
  PAP_462: 'Acrylic 75 x 50 cm',
  PAP_463: 'Acrylic 90 x 30 cm',
  PAP_464: 'Acrylic 90 x 60 cm',
  PAP_465: 'Acrylic 100 x 50 cm',
  PAP_466: 'Acrylic 100 x 75 cm',
  PAP_467: 'Acrylic 105 x 70 cm',
  PAP_468: 'Acrylic 120 x 40 cm',
  PAP_469: 'Acrylic 120 x 60 cm',
  PAP_470: 'Acrylic 20 x 30 cm',
  PAP_471: 'Acrylic 30 x 40 cm',
  PAP_472: 'Acrylic 40 x 60 cm',
  PAP_473: 'Acrylic 50 x 70 cm',
  PAP_474: 'Acrylic 70 x 100 cm',
  PAP_475: 'Acrylic 80 x 120 cm',
  PAP_476: 'Acrylic 60 x 80 cm',
  PAP_477: 'Acrylic 120 x 90 cm',
  PAP_478: 'Acrylic 150 x 100 cm',
  PAP_479: 'Acrylic 200 x 100 cm',
  PAP_480: 'Acrylic 30 x 30 cm',
  PAP_481: 'Acrylic 40 x 40 cm',
  PAP_482: 'Acrylic 50 x 50 cm',
  PAP_483: 'Acrylic 60 x 60 cm',
  PAP_484: 'Acrylic 70 x 70 cm',
  PAP_485: 'Acrylic 80 x 80 cm',
  PAP_486: 'Acrylic 100 x 100 cm',
  PAP_487: 'Acrylic 20 x 20 cm',
  PAP_488: 'Acrylic 21 x 28 cm',
  PAP_489: 'Acrylic 30 x 45 cm',
  PAP_490: 'Acrylic 40 x 50 cm',
  PAP_491: 'Acrylic 45 x 60 cm',
  PAP_492: 'Acrylic 50 x 75 cm',
  PAP_493: 'Acrylic 60 x 90 cm',
  PAP_494: 'Acrylic 75x 100 cm',
  PAP_495: 'Acrylic 70 x 105 cm',
  PAP_496: 'Acrylic 90 x 120 cm',
  PAP_497: 'Acrylic 100 x 150 cm',
  PAP_498: 'Aluminium 60 x 90 cm',
  PAP_499: 'Aluminium 90 x 60 cm',
  PAP_500: 'Aluminium 28 x 21 cm',
  PAP_504: 'Aluminium 45 x 30 cm',
  PAP_505: 'Aluminium 50 x 40 cm',
  PAP_506: 'Aluminium 60 x 30 cm',
  PAP_507: 'Aluminium 60 x 45 cm',
  PAP_508: 'Aluminium 75 x 50 cm',
  PAP_509: 'Aluminium 90 x 30 cm',
  PAP_510: 'Aluminium 30 x 20 cm',
  PAP_511: 'Aluminium 40 x 30 cm',
  PAP_512: 'Aluminium 60 x 40 cm',
  PAP_513: 'Aluminium 70 x 50 cm',
  PAP_514: 'Aluminium 80 x 60 cm',
  PAP_515: 'Aluminium 100 x 70 cm',
  PAP_516: 'Aluminium 120 x 80 cm',
  PAP_517: 'Aluminium 20 x 30 cm',
  PAP_518: 'Aluminium 30 x 40 cm',
  PAP_519: 'Aluminium 40 x 60 cm',
  PAP_520: 'Aluminium 50 x 70 cm',
  PAP_521: 'Aluminium 60 x 80 cm',
  PAP_522: 'Aluminium 70 x 100 cm',
  PAP_523: 'Aluminium 80 x 120 cm',
  PAP_524: 'Aluminium 30 x 30 cm',
  PAP_525: 'Aluminium 40 x 40 cm',
  PAP_526: 'Aluminium 50 x 50 cm',
  PAP_527: 'Aluminium 60 x 60 cm',
  PAP_528: 'Aluminium 70 x 70 cm',
  PAP_529: 'Aluminium 80 x 80 cm',
  PAP_530: 'Aluminium 100 x 100 cm',
  PAP_531: 'Aluminium 20 x 20 cm',
  PAP_532: 'Aluminium 100 x 50 cm',
  PAP_533: 'Aluminium 100 x 75 cm',
  PAP_534: 'Aluminium 105 x 70 cm',
  PAP_535: 'Aluminium 120 x 40 cm',
  PAP_536: 'Aluminium 120 x 60 cm',
  PAP_537: 'Aluminium 120 x 90 cm',
  PAP_538: 'Aluminium 150 x 100 cm',
  PAP_539: 'Aluminium 200 x 100 cm',
  PAP_540: 'Forex (Mounted Print) 30 x 20 cm',
  PAP_541: 'Forex (Mounted Print) 40 x 30 cm',
  PAP_542: 'Forex (Mounted Print) 60 x 40 cm',
  PAP_543: 'Forex (Mounted Print) 70 x 50 cm',
  PAP_544: 'Forex (Mounted Print) 80 x 60 cm',
  PAP_545: 'Forex (Mounted Print) 100 x 70 cm',
  PAP_546: 'Forex (Mounted Print) 120 x 80 cm',
  PAP_547: 'Forex (Mounted Print) 20 x 30 cm',
  PAP_548: 'Forex (Mounted Print) 30 x 40 cm',
  PAP_549: 'Forex (Mounted Print) 40 x 60 cm',
  PAP_552: 'Forex (Mounted Print) 70 x 100 cm',
  PAP_553: 'Forex (Mounted Print) 80 x 120 cm',
  PAP_554: 'Forex (Mounted Print) 30 x 30 cm',
  PAP_555: 'Forex (Mounted Print) 40 x 40 cm',
  PAP_556: 'Forex (Mounted Print) 50 x 50 cm',
  PAP_557: 'Forex (Mounted Print) 60 x 60 cm',
  PAP_558: 'Forex (Mounted Print) 70 x 70 cm',
  PAP_559: 'Forex (Mounted Print) 80 x 80 cm',
  PAP_560: 'Forex (Mounted Print) 100 x 100 cm',
  PAP_561: 'Forex (Mounted Print) 20 x 20 cm',
  PAP_562: 'Forex (Mounted Print) 50 x 70 cm',
  PAP_563: 'Forex (Mounted Print) 60 x 80 cm',
  PAP_564: 'Forex (Mounted Print) 28 x 21 cm',
  PAP_565: 'Forex (Mounted Print) 45 x 30 cm',
  PAP_566: 'Forex (Mounted Print) 50 x 40 cm',
  PAP_567: 'Forex (Mounted Print) 60 x 30 cm',
  PAP_568: 'Forex (Mounted Print) 60 x 45 cm',
  PAP_569: 'Forex (Mounted Print) 75 x 50 cm',
  PAP_570: '(DISCONTINUED) Photo on wood 30 x 20 cm',
  PAP_571: '(DISCONTINUED) Photo on wood 40 x 30 cm',
  PAP_572: '(DISCONTINUED) Photo on wood 60 x 40 cm',
  PAP_573: '(DISCONTINUED) Photo on wood 70 x 50 cm',
  PAP_574: '(DISCONTINUED) Photo on wood 80 x 60 cm',
  PAP_575: '(DISCONTINUED) Photo on wood 100 x 70 cm',
  PAP_576: '(DISCONTINUED) Photo on wood 120 x 80 cm',
  PAP_577: '(DISCONTINUED) Photo on wood 20 x 30 cm',
  PAP_578: '(DISCONTINUED) Photo on wood 30 x 40 cm',
  PAP_579: '(DISCONTINUED) Photo on wood 40 x 60 cm',
  PAP_580: '(DISCONTINUED) Photo on wood 50 x 70 cm',
  PAP_581: '(DISCONTINUED) Photo on wood 60 x 80 cm',
  PAP_582: '(DISCONTINUED) Photo on wood 70 x 100 cm',
  PAP_583: '(DISCONTINUED) Photo on wood 80 x 120 cm',
  PAP_584: '(DISCONTINUED) Photo on wood 20 x 20 cm',
  PAP_585: '(DISCONTINUED) Photo on wood 30 x 30 cm',
  PAP_586: '(DISCONTINUED) Photo on wood 40 x 40 cm',
  PAP_587: '(DISCONTINUED) Photo on wood 50 x 50 cm',
  PAP_588: '(DISCONTINUED) Photo on wood 60 x 60 cm',
  PAP_589: '(DISCONTINUED) Photo on wood 70 x 70 cm',
  PAP_590: '(DISCONTINUED) Photo on wood 80 x 80 cm',
  PAP_591: '(DISCONTINUED) Photo on wood 100 x 100 cm',
  PAP_592: 'Forex (Mounted Print) 90 x 30 cm',
  PAP_593: 'Forex (Mounted Print) 90 x 60 cm',
  PAP_594: 'Forex (Mounted Print) 100 x 50 cm',
  PAP_595: 'Forex (Mounted Print) 100 x 75 cm',
  PAP_596: 'Forex (Mounted Print) 105 x 70 cm',
  PAP_597: 'Forex (Mounted Print) 120 x 40 cm',
  PAP_598: 'Forex (Mounted Print) 120 x 60 cm',
  PAP_599: 'Forex (Mounted Print) 120 x 90 cm',
  PAP_600: 'Forex (Mounted Print) 150 x 100 cm',
  PAP_604: 'Forex (Mounted Print) 200 x 100 cm',
  PAP_610: 'Poster 30 x 20 cm',
  PAP_611: 'Poster 40 x 30 cm',
  PAP_612: 'Poster 60 x 40 cm',
  PAP_613: 'Poster 70 x 50 cm',
  PAP_614: 'Poster 80 x 60 cm',
  PAP_615: 'Poster 100 x 70 cm',
  PAP_616: 'Poster 120 x 80 cm',
  PAP_617: 'Poster 20 x 30 cm',
  PAP_618: 'Poster 30 x 40 cm',
  PAP_619: 'Poster 40 x 60 cm',
  PAP_620: 'Poster 50 x 70 cm',
  PAP_621: 'Poster 60 x 80 cm',
  PAP_622: 'Poster 70 x 100 cm',
  PAP_623: 'Poster 80 x 120 cm',
  PAP_624: 'Poster 20 x 20 cm',
  PAP_625: 'Poster 30 x 30 cm',
  PAP_626: 'Poster 40 x 40 cm',
  PAP_627: 'Poster 50 x 50 cm',
  PAP_628: 'Poster 60 x 60 cm',
  PAP_629: 'Poster 70 x 70 cm',
  PAP_630: 'Poster 80 x 80 cm',
  PAP_631: 'Poster 100 x 100 cm',
  PAP_632: 'Poster 28 X 21 cm',
  PAP_634: 'Poster 45 X 30 cm',
  PAP_635: 'Poster 50 X 40 cm',
  PAP_636: 'Poster 60 X 30 cm',
  PAP_637: 'Poster 60 X 45 cm',
  PAP_638: 'Poster 75 X 50 cm',
  PAP_639: 'Poster 90 X 30 cm',
  PAP_640: 'Poster 90 X 60 cm',
  PAP_641: 'Poster 100 X 50 cm',
  PAP_642: 'Poster 100 X 75 cm',
  PAP_643: 'Poster 105 X 70 cm',
  PAP_644: 'Poster 120 X 40 cm',
  PAP_645: 'Poster 120 X 60 cm',
  PAP_646: 'Poster 120 X 90 cm',
  PAP_647: 'Poster 150 X 100 cm',
  PAP_648: 'Poster 200 X 100 cm',
  PAP_649: 'Poster 21 X 28 cm',
  PAP_650: '(DISCONTINUED) Portrait Calendar (A5)',
  PAP_651: '(DISCONTINUED) Landscape Calendar Double S',
  PAP_652: '(DISCONTINUED) Calendar Portrait (A4)',
  PAP_653: 'Calendar Double A4',
  PAP_654: '(DISCONTINUED) Calendar Portrait (A3)',
  PAP_655: '(DISCONTINUED) Calendar Double (A3)',
  PAP_656: '(DISCONTINUED) Calendar Landscape (A3)',
  PAP_657: '(DISCONTINUED) Portrait Calendar XL',
  PAP_658: 'Calendar Square 20 x 20 cm',
  PAP_659: 'Calendar Portrait A4 20x30 cm',
  PAP_660: 'A3 Year Calendar',
  PAP_665: 'A5 Calendar Portrait',
  PAP_666: 'A5 Calendar Landscape',
  PAP_667: 'A4 Calendar Landscape',
  PAP_668: 'A3 Calendar Landscape',
  PAP_670: 'Poster 30 X 45 cm',
  PAP_671: 'Poster 40 X 50 cm',
  PAP_672: 'Poster 45 X 60 cm',
  PAP_673: 'Poster 50 X 75 cm',
  PAP_674: 'Poster 60 X 90 cm',
  PAP_675: 'Poster 75 X 100 cm',
  PAP_676: 'Poster 70 X 105 cm',
  PAP_677: 'Poster 90 X 120 cm',
  PAP_678: 'Poster 100 X 150 cm',
  PAP_680: 'Aluminium 21 x 28 cm',
  PAP_681: 'Aluminium 30 x 45 cm',
  PAP_682: 'Aluminium 40 x 50 cm',
  PAP_683: 'Aluminium 45 x 60 cm',
  PAP_684: 'Aluminium 50 x 75 cm',
  PAP_685: 'Aluminium 75 x 100 cm',
  PAP_686: 'Aluminium 70 x 105 cm',
  PAP_687: 'Aluminium 90 x 120 cm',
  PAP_688: 'Aluminium 100 x 150 cm',
  PAP_690: 'Forex (Mounted Print) 21 x 28 cm',
  PAP_691: 'Forex (Mounted Print) 30 x 45 cm',
  PAP_692: 'Forex (Mounted Print) 40 x 50 cm',
  PAP_693: 'Forex (Mounted Print) 45 x 60 cm',
  PAP_694: 'Forex (Mounted Print) 50 x 75 cm',
  PAP_695: 'Forex (Mounted Print) 60 x 90 cm',
  PAP_696: 'Forex (Mounted Print) 75 x 100 cm',
  PAP_697: 'Forex (Mounted Print) 70 x 105 cm',
  PAP_698: 'Forex (Mounted Print) 90 x 120 cm',
  PAP_699: 'Forex (Mounted Print) 100 x 150 cm',
  PAP_700: 'Canvas 70 x 105 cm',
  PAP_701: 'Canvas 90 x 120 cm',
  PAP_702: 'Canvas 100 x 150 cm',
  PAP_703: 'Poster A4 Landscape',
  PAP_704: 'Poster A3 Landscape',
  PAP_705: 'Poster A2 Landscape',
  PAP_706: 'Poster A1 Landscape',
  PAP_707: 'Poster A4 Portrait',
  PAP_708: 'Poster A3 Portrait',
  PAP_709: 'Poster A2 Portrait',
  PAP_710: 'Poster A1 Portrait',
  PAP_711: '(DISCONTINUED) Phonecase iPhone 4/4S',
  PAP_712: '(DISCONTINUED) Phonecase iPhone  5/5S',
  PAP_713: '(DISCONTINUED) Phonecase Samsung Galaxy S3',
  PAP_714: '(DISCONTINUED) Phonecase Samsung Galaxy S4',
  PAP_715: '(DISCONTINUED) Phonecase Samsung Galaxy S5',
  PAP_716: '(DISCONTINUED) Phonecase Samsung Galaxy S4 MINI',
  PAP_717: '(DISCONTINUED) Phonecase iPhone 6',
  PAP_718: '(DISCONTINUED) Phonecase iPhone 6 PLUS',
  PAP_720: 'Mug',
  PAP_721: 'Wraparound Mug',
  PAP_722: 'Love Mug',
  PAP_723: 'Wraparound Love Mug',
  PAP_724: 'Magic Mug',
  PAP_725: 'Wraparound Magic Mug',
  PAP_730: 'Canvas 4cm 28x21',
  PAP_731: 'Canvas 4cm 21x28',
  PAP_732: 'Canvas 4cm 30x20',
  PAP_733: 'Canvas 4cm 20x30',
  PAP_734: 'Canvas 4cm 40x30',
  PAP_735: 'Canvas 4cm 30x40',
  PAP_736: 'Canvas 4cm 45x30',
  PAP_737: 'Canvas 4cm 30x45',
  PAP_738: 'Canvas 4cm 50x40',
  PAP_739: 'Canvas 4cm 40x50',
  PAP_740: 'Canvas 4cm 60x30',
  PAP_741: 'Canvas 4cm 60x40',
  PAP_742: 'Canvas 4cm 40x60',
  PAP_743: 'Canvas 4cm 60x45',
  PAP_744: 'Canvas 4cm 45x60',
  PAP_745: 'Canvas 4cm 70x50',
  PAP_746: 'Canvas 4cm 50x70',
  PAP_747: 'Canvas 4cm 75x50',
  PAP_748: 'Canvas 4cm 50x75',
  PAP_749: 'Canvas 4cm 80x60',
  PAP_750: 'Canvas 4cm 60x80',
  PAP_751: 'Canvas 4cm 90x30',
  PAP_752: 'Canvas 4cm 90x60',
  PAP_753: 'Canvas 4cm 60x90',
  PAP_754: 'Canvas 4cm 100x50',
  PAP_755: 'Canvas 4cm 100x70',
  PAP_756: 'Canvas 4cm 70x100',
  PAP_757: 'Canvas 4cm 100x75',
  PAP_758: 'Canvas 4cm 75x100',
  PAP_759: 'Canvas 4cm 105x70',
  PAP_760: 'Canvas 4cm 70x105',
  PAP_761: 'Canvas 4cm 120x40',
  PAP_762: 'Canvas 4cm 120x60',
  PAP_763: 'Canvas 4cm 120x80',
  PAP_764: 'Canvas 4cm 80x120',
  PAP_765: 'Canvas 4cm 120x90',
  PAP_766: 'Canvas 4cm 90x120',
  PAP_767: 'Canvas 4cm 150x100',
  PAP_768: 'Canvas 4cm 100x150',
  PAP_769: 'Canvas 4cm 200x100',
  PAP_770: 'Canvas 4cm 20x20',
  PAP_771: 'Canvas 4cm 30x30',
  PAP_772: 'Canvas 4cm 40x40',
  PAP_773: 'Canvas 4cm 50x50',
  PAP_774: 'Canvas 4cm 60x60',
  PAP_775: 'Canvas 4cm 70x70',
  PAP_776: 'Canvas 4cm 80x80',
  PAP_777: 'Canvas 4cm 100x100',
  PAP_780: 'Wooden Photo Block 15x10 cm',
  PAP_781: 'Wooden Photo Block 10x15 cm',
  PAP_782: 'Wooden Photo Block 12x12 cm',
  PAP_783: 'Wooden Photo Block 17x12 cm',
  PAP_784: 'Wooden Photo Block 12x17 cm',
  PAP_785: 'Wooden Photo Block 20x15 cm',
  PAP_786: 'Wooden Photo Block 15x20 cm',
  PAP_787: 'Acrylic Photo Block 15x10 cm',
  PAP_788: 'Acrylic Photo Block 10x15 cm',
  PAP_789: 'Acrylic Photo Block 20x15 cm',
  PAP_790: 'Acrylic Photo Block 15x20 cm',
  PAP_810: '5cm Square Magnet',
  PAP_811: '7.5cm Square Magnet',
  PAP_830: '(DISCONTINUED) Double Cards Square 11,5x11,5 cm',
  PAP_831: '(DISCONTINUED) Double Cards Square 13,6x13,6 cm',
  PAP_834: '(DISCONTINUED) Double Cards Landscape 15x10 cm',
  PAP_835: '(DISCONTINUED) Double Cards Portrait 10x15 cm',
  PAP_836: '(DISCONTINUED) Greeting Cards Landscape (18x12 cm)',
  PAP_837: '(DISCONTINUED) Greeting Cards Portrait (12x18 cm)',
  PAP_850: 'Aluminium - Butler 100x100',
  PAP_851: 'Aluminium - Butler 28x21',
  PAP_852: 'Aluminium - Butler 21x28',
  PAP_853: 'Aluminium - Butler 30x20',
  PAP_854: 'Aluminium - Butler 20x30',
  PAP_855: 'Aluminium - Butler 40x30',
  PAP_856: 'Aluminium - Butler 30x40',
  PAP_857: 'Aluminium - Butler 45x30',
  PAP_858: 'Aluminium - Butler 30x45',
  PAP_859: 'Aluminium - Butler 50x40',
  PAP_860: 'Aluminium - Butler 40x50',
  PAP_861: 'Aluminium - Butler 60x30',
  PAP_862: 'Aluminium - Butler 60x40',
  PAP_863: 'Aluminium - Butler 40x60',
  PAP_864: 'Aluminium - Butler 60x45',
  PAP_865: 'Aluminium - Butler 45x60',
  PAP_866: 'Aluminium - Butler 70x50',
  PAP_867: 'Aluminium - Butler 50x70',
  PAP_868: 'Aluminium - Butler 75x50',
  PAP_869: 'Aluminium - Butler 50x75',
  PAP_870: 'Aluminium - Butler 80x60',
  PAP_871: 'Aluminium - Butler 60x80',
  PAP_872: 'Aluminium - Butler 90x30',
  PAP_873: 'Aluminium - Butler 90x60',
  PAP_874: 'Aluminium - Butler 60x90',
  PAP_875: 'Aluminium - Butler 100x50',
  PAP_876: 'Aluminium - Butler 100x70',
  PAP_877: 'Aluminium - Butler 70x100',
  PAP_878: 'Aluminium - Butler 100x75',
  PAP_879: 'Aluminium - Butler 75x100',
  PAP_880: 'Aluminium - Butler 105x70',
  PAP_881: 'Aluminium - Butler 70x105',
  PAP_882: 'Aluminium - Butler 120x40',
  PAP_883: 'Aluminium - Butler 120x60',
  PAP_884: 'Aluminium - Butler 120x80',
  PAP_885: 'Aluminium - Butler 80x120',
  PAP_886: 'Aluminium - Butler 120x90',
  PAP_887: 'Aluminium - Butler 90x120',
  PAP_888: 'Aluminium - Butler 150x100',
  PAP_889: 'Aluminium - Butler 100x150',
  PAP_890: 'Aluminium - Butler 200x100',
  PAP_891: 'Aluminium - Butler 20x20',
  PAP_892: 'Aluminium - Butler 30x30',
  PAP_893: 'Aluminium - Butler 40x40',
  PAP_894: 'Aluminium - Butler 50x50',
  PAP_895: 'Aluminium - Butler 60x60',
  PAP_896: 'Aluminium - Butler 70x70',
  PAP_897: 'Aluminium - Butler 80x80',
  PAP_910: 'Prints 10-Width',
  PAP_911: 'Prints 13-Width',
  PAP_912: 'Prints Polaroid 10 x 12 cm',
  PAP_913: 'Prints Special 13 x 18 cm',
  PAP_914: 'Prints Enlargement 20 x 30 cm',
  PAP_915: 'Prints Enlargement 15 x 20 cm',
  PAP_920: 'S square Cushion',
  PAP_921: 'M square Cushion',
  PAP_922: 'L square Cushion',
  PAP_930: '(DISCONTINUED) Photo Paper Cards 10 x 10 cm',
  PAP_931: '(DISCONTINUED) Photo Paper Cards 15 x 15 cm',
  PAP_932: '(DISCONTINUED) Photo Paper Cards 10 x 15 cm',
  PAP_933: '(DISCONTINUED) Photo Paper Cards 15 x 10 cm',
  PAP_934: '(DISCONTINUED) Photo Paper Cards 13 x 19 cm',
  PAP_935: '(DISCONTINUED) Photo Paper Cards 19 x 13 cm',
  PAP_940: 'Two sided/Flat Cards 10 x 10 cm',
  PAP_941: 'Two sided/Flat Cards 15 x 15 cm',
  PAP_942: 'Two sided/Flat Cards 10 x 15 cm',
  PAP_943: 'Two sided/Flat Cards 15 x 10 cm',
  PAP_944: 'Two sided/Flat Cards 13 x 19 cm',
  PAP_945: 'Two sided/Flat Cards 19 x 13 cm',
  PAP_950: 'Double/Folded Cards 10 x 10 cm',
  PAP_951: 'Double/Folded Cards 15 x 15 cm',
  PAP_952: 'Double/Folded Cards 10 x 15 cm',
  PAP_953: 'Double/Folded Cards 15 x 10 cm',
  PAP_954: 'Double/Folded Cards 13 x 19 cm',
  PAP_955: 'Double/Folded Cards 19 x 13 cm',
  PAP_960: '(DISCONTINUED) Square Photo Calendar 20 x 20 cm',
  PAP_961: '(DISCONTINUED) Photo Calendar 20 x 30 cm',
  PAP_962: 'Desk Calendar',
  PAP_963: 'Slim Calendar 15x40',
  PAP_964: 'Easel Calendar Portrait',
  PAP_965: 'Easel Calendar Landscape',
  PAP_970: 'XS Ravensburger Jigsaw Puzzle - 49 Pieces',
  PAP_971: 'S Ravensburger Jigsaw Puzzle - 100 Pieces',
  PAP_972: 'M Ravensburger Jigsaw Puzzle - 200 Pieces',
  PAP_973: 'M Ravensburger Jigsaw Puzzle - 500 Pieces',
  PAP_974: 'L Ravensburger Jigsaw Puzzle - 1000 Pieces',
  PAP_975: 'XL Ravensburger Jigsaw Puzzle - 2000 Pieces',
  PAP_977: 'S Ravensburger Jigsaw Puzzle - 100 Pieces',
  PAP_978: 'M Ravensburger Jigsaw Puzzle - 200 Pieces',
  PAP_979: 'M Ravensburger Jigsaw Puzzle - 500 Pieces',
  PAP_980: 'L Ravensburger Jigsaw Puzzle - 1000 Pieces',
  PAP_981: 'XL Ravensburger Jigsaw Puzzle - 2000 Pieces',
}

const studentenProductTypeNames: { [id: string]: string } = {
  EXP: 'Expeditionsavgift',
  POR1: 'Porto',
  POR2: 'Porto',
  POR3: 'Porto',
  BA: 'Studentbanderoll',
  BB: 'Studentbanderoll',
  BC: 'Studentbanderoll',
  BD: 'Studentbanderoll',
  BE: 'Studentbanderoll',
  BF: 'Studentbanderoll',
  BG: 'Studentbanderoll',
  BH: 'Studentbanderoll',
  BI: 'Studentbanderoll',
  BJ: 'Studentbanderoll',
  NA: 'Studentnalle',
  INB: 'Inbjudningskort 10-pack',
  VP: 'Visselpipa',
  PA: 'Poster',
  PB: 'Poster',
  PC: 'Poster',
  PD: 'Poster',
  PE: 'Poster',
  PF: 'Poster',
  PG: 'Poster',
  PH: 'Poster',
  PI: 'Poster',
  PJ: 'Poster',
  KA: 'Klassiskt Plakat',
  KB: 'Klassiskt Plakat',
  KC: 'Klassiskt Plakat',
  KD: 'Klassiskt Plakat',
  KE: 'Klassiskt Plakat',
  KF: 'Klassiskt Plakat',
  KG: 'Klassiskt Plakat',
  KH: 'Klassiskt Plakat',
  KI: 'Klassiskt Plakat',
  KJ: 'Klassiskt Plakat',
  LA: 'Lyxplakat',
  LB: 'Lyxplakat',
  LC: 'Lyxplakat',
  LD: 'Lyxplakat',
  LE: 'Lyxplakat',
  LF: 'Lyxplakat',
  LG: 'Lyxplakat',
  LH: 'Lyxplakat',
  LI: 'Lyxplakat',
  LJ: 'Lyxplakat',
  BK: 'Studentbanderoll',
  BL: 'Studentbanderoll',
  BM: 'Studentbanderoll',
  BN: 'Studentbanderoll',
  BO: 'Studentbanderoll',
  BP: 'Studentbanderoll',
  BQ: 'Studentbanderoll',
  BR: 'Studentbanderoll',
  BS: 'Studentbanderoll',
  BT: 'Studentbanderoll',
  BU: 'Studentbanderoll',
  BV: 'Studentbanderoll',
  BW: 'Studentbanderoll',
  BX: 'Studentbanderoll',
  PK: 'Poster',
  PL: 'Poster',
  PM: 'Poster',
  PN: 'Poster',
  PO: 'Poster',
  PP: 'Poster',
  PQ: 'Poster',
  PR: 'Poster',
  PS: 'Poster',
  PT: 'Poster',
  PU: 'Poster',
  PV: 'Poster',
  PW: 'Poster',
  PX: 'Poster',
  KK: 'Klassiskt Plakat',
  KL: 'Klassiskt Plakat',
  KM: 'Klassiskt Plakat',
  KN: 'Klassiskt Plakat',
  KO: 'Klassiskt Plakat',
  KP: 'Klassiskt Plakat',
  KQ: 'Klassiskt Plakat',
  KR: 'Klassiskt Plakat',
  KS: 'Klassiskt Plakat',
  KT: 'Klassiskt Plakat',
  KU: 'Klassiskt Plakat',
  KV: 'Klassiskt Plakat',
  KW: 'Klassiskt Plakat',
  KX: 'Klassiskt Plakat',
  LK: 'Lyxplakat',
  LL: 'Lyxplakat',
  LM: 'Lyxplakat',
  LN: 'Lyxplakat',
  LO: 'Lyxplakat',
  LP: 'Lyxplakat',
  LQ: 'Lyxplakat',
  LR: 'Lyxplakat',
  LS: 'Lyxplakat',
  LT: 'Lyxplakat',
  LU: 'Lyxplakat',
  LV: 'Lyxplakat',
  LW: 'Lyxplakat',
  LX: 'Lyxplakat',
}

const VoucherProductId = 'PAP_050'

const editorDescriptions: { [editor: string]: EditorDescription | undefined } = {
  'Canvas Editor': { name: 'Canvas', type: 'web' },
  CMS: { name: 'CMS', type: 'web' },
  'Easy Flow Agenda': { name: 'Easy Flow Agenda', type: 'web' },
  'Easy Flow Calendar': { name: 'Easy Flow Calendar', type: 'web' },
  'Easy Flow Photobook': { name: 'Easy Flow Photobook', type: 'web' },
  EasyFlow: { name: 'Easy Flow', type: 'ef' },
  'Greeting Cards Editor': { name: 'Greeting Cards Editor', type: 'web' },
  'HTML5 Photobook Editor': { name: 'HTML5 Photobook Editor', type: 'web' },
  'Ipad Editor': { name: 'iPad', type: 'app' },
  'iPad Photobook Editor': { name: 'iPad Photobook', type: 'app' },
  'Mac Editor': { name: 'Mac', type: 'apple' },
  MacEditor: { name: 'Mac', type: 'apple' },
  MEDIACLIP: { name: 'Mediaclip', type: 'web' },
  'Mug Editor': { name: 'Mug', type: 'web' },
  'NeXt Editor AIR': { name: 'NeXt Editor AIR', type: 'web' },
  'NeXt Editor Web': { name: 'NeXt Editor Web', type: 'web' },
  NeXtEditorWeb: { name: 'NeXt Editor Web', type: 'web' },
  'Prints Editor': { name: 'Prints', type: 'web' },
  Resnap: { name: 'Resnap', type: 'web' },
  'Smartphone Editor': { name: 'Smartphone', type: 'app' },
  'Windows Editor': { name: 'Windows', type: 'win' },
  WindowsEditor: { name: 'Windows', type: 'win' },
  'X-Sell': { name: 'X-Sell', type: 'xs' },
  Studenten: { name: 'Studenten', type: 'web' },
  'Instant Editor': { name: 'Instant Editor', type: 'ie' },
  'IE App': { name: 'IE App', type: 'ieApp' },
  'OE App': { name: 'OE App', type: 'oeApp' },
}

const carrierNamesMapping: { [carrierName: string]: string } = {
  'Colis Prive': 'ColisPrive 48H FR unsigned',
  'Colis Prive International': 'ColisPrive International',
  Chronopost: 'Chronopost FR Chrono 18',
  'Chronopost Express International': 'Chronopost Express International',
  'Chronopost Classic International': 'Chronopost Classic EU',
  'Chronopost PUDO': 'Chronopost FR 2shopDirect',
  'Chronopost PUDO Next Day': 'Chronopost FR Chrono Relais 13',
  Calberson: 'Over20kg',
  'PostNL Brievenbus': 'PostNL Brievenbus',
  'PostNL Parcel AVG NL': 'PostNL Parcel AVG NL',
  'PostNL Parcel AVG NL PUDO': 'PostNL Parcel AVG NL',
  'PostNL Parcel EPS EU': 'PostNL Parcel EPS EU',
  'PostNL Parcel EPS EU PUDO': 'PostNL Parcel EPS EU',
  'PostNL Int. Priority Mail': 'PostNL Int. Priority Mail',
  'PostNL Parcel EPS EU 3': 'PostNL Parcel EPS EU 3',
  'PostNL Parcel EPS FR': 'PostNL Parcel EPS FR',
  'DHL Economy': 'DHL Economy',
  'DHL Express': 'DHL Express',
  'Norden Posten': 'DirectLink MMP2 - untracked',
  'Direct Link Tracked': 'DirectLink MMP3 - tracked',
  'DHL Packet': 'DHL Paket',
  'Bring Mail': 'Bring Mail',
  'Bring Parcel': 'Bring Parcel',
  'Royal Mail Tracked 48': 'RoyalMail Tracked 48',
  'Royal Mail Tracked 24': 'Royal Mail Tracked 24',
  'Hermes 48': 'EVRI Hermes',
}

// This map is used in resolveProductionOrderProductStatuses to calculate partial product statuses given accumulative quantities
// Precedence matters in this map. So that if the statuses input is Accepted(1) and WaitingForProduction(1), the result will be
// WaitingForProduction(1), because WaitingForProduction comes after Accepted in this map
// Delayed and Error are exceptions; they stain the product regardless of their position here
const productionOrderProductStatusMapping: Map<string, ProductionOrderProductStatus> = new Map([
  ['Accepted', ProductionOrderProductStatus.Accepted],
  ['WaitingForProduction', ProductionOrderProductStatus.WaitingForProduction],
  ['Producing', ProductionOrderProductStatus.InProduction],
  ['Delayed', ProductionOrderProductStatus.Delayed],
  ['Packed', ProductionOrderProductStatus.Packed],
  ['PartiallyShipped', ProductionOrderProductStatus.PartiallyShipped],
  ['Shipped', ProductionOrderProductStatus.ScannedForShipment],
  ['Cancelled', ProductionOrderProductStatus.Cancelled],
  ['Error', ProductionOrderProductStatus.Error],
])

const eventNameToStatusValueMap: Map<string, string> = new Map([
  ['accepted', 'Accepted'],
  ['waiting-for-production', 'WaitingForProduction'],
  ['producing', 'Producing'],
  ['delayed', 'Delayed'],
  ['packed', 'Packed'],
  ['shipped', 'Shipped'],
  ['cancelled', 'Cancelled'],
  ['error', 'Error'],
  ['partially-shipped', 'PartiallyShipped'],
])

const unknownEditor: EditorDescription = { name: 'Unknown editor', type: '?' }

const plantNames: { [plant: string]: string | undefined } = {
  YPB: 'Ypenburg, The Netherlands',
  NLH: 'Nanteuil-le-Haudouin, France',
  KIS: 'Kisarazu, Japan',
  BHI: 'Bhiwandi, India',
  FRZ: 'Fritzell, Sweden',
  RRD: 'RRDonnelley, Czech Republic',
  ELA: 'Elanders, Germany',
  ELA_SE: 'Elanders, Sweden',
  KHS: 'PosterXXL, Germany',
  SYM: 'Symphony Test System',
  BDK: 'Bosch-Druck, Germany',
  CCC: 'ColorCentric Corporation, USA',
  COOVZ: 'Coovz, France',
  CTM: 'Cartamundi, Belgium',
  EKT: 'Exakta, Sweden',
  HAR: 'Harrier, United Kingdom',
  HOF_ES: 'Paterna, Spain',
  JONDO: 'Jondo, United Kingdom',
  MM: 'Micro Magnetics, United Kingdom',
  ORWO: 'Orwo, Germany',
  PMI: 'PMI, Australia',
  PP: 'Precision Printing, United Kingdom',
  RAV: 'Ravensburger, Germany',
  SART: 'Sartrouville, France',
  WFR: 'Willen Field Road, United Kingdom',
  TBD: 'To Be Decided',
}

export interface ProductOptionItem {
  name: string
  value: string | undefined
}

export interface PlantInfo {
  plant: FulfillmentPlant
  displayName: string
}

export interface OrderCampaignInfo {
  campaignName?: string
  unpromised: boolean
  isBrokenPromise: boolean
}

export enum ReuploadState {
  Requested,
  Completed,
  Cancelled,
}

export interface ReuploadInfo {
  image: string
  title: string
  state: ReuploadState
}

export const studentenPlant: PlantInfo = {
  plant: FulfillmentPlant.FRZ,
  displayName: FulfillmentPlant.FRZ
}

export const studentenEditor: Editor = {
  name: 'Studenten',
}

export class OrderDetailsService {
  resolveProductDescription(productId: string): ReactNode {
    let productDescription = `${this.resolveProductDescriptionByType(productId) || ''} [${productId}]`

    return productDescription
  }

  isPaid(data: OrderTypes.OrderListItemModel): boolean {
    return !!data.paymentHistory && data.paymentHistory.some((p) => this.isPaymentSuccessful(p))
  }

  isPaymentSuccessful(paymentInfo: OrderTypes.PaymentHistory) {
    return this.getPaymentStatus(paymentInfo) === OrderProductStatus.PaymentSucceeded
  }

  getPaymentStatus(paymentInfo: OrderTypes.PaymentHistory): OrderProductStatus {
    if (paymentInfo.event === 'PENDING') {
      return OrderProductStatus.WaitingForPayment
    } else if (paymentInfo.success === false || paymentInfo.event === 'CANCELLATION') {
      return OrderProductStatus.PaymentFailed
    } else {
      return OrderProductStatus.PaymentSucceeded
    }
  }

  resolveOrderStatusBadgeSettings(data: OrderTypes.OrderListItemModel, detailed: boolean = true): BadgeSetting {
    const oldBadge = this.resolveOrderStatusBadgeSettingsOld(data, detailed)
    const newBadge = this.resolveOrderStatusBadgeSettingsNew(data)

    if (newBadge.title === OrderProductStatus.Unknown) {
      LogApiInstance.error(new LogMessage(`${data.id}: unknown status`, codes.orderDetailsError))
    }

    return newBadge
  }

  resolveOrderStatusBadgeSettingsOld(data: OrderTypes.OrderListItemModel, detailed: boolean = true): BadgeSetting {
    if (data.products) {
      if (data.products.every((p) => p.status === 'Cancelled')) {
        return { color: 'warning', title: OrderProductStatus.Cancelled }
      } else if (!data.paymentHistory || data.paymentHistory.length === 0) {
        if (detailed && data.products.every((p) => p.status === 'Received')) {
          return { color: 'waiting', title: OrderProductStatus.WaitingForPaymentNotConfirmed }
        } else if (detailed && data.products.every((p) => p.status === 'Confirmed')) {
          return { color: 'waiting', title: OrderProductStatus.WaitingForPaymentConfirmed }
        } else {
          return { color: 'waiting', title: OrderProductStatus.WaitingForPayment }
        }
      } else {
        const [paymentInfo] = data.paymentHistory
        const paymentStatus = this.getPaymentStatus(paymentInfo)
        return { color: paymentStatus === OrderProductStatus.PaymentFailed ? 'danger' : 'waiting', title: paymentStatus }
      }
    }

    return { color: 'other', title: OrderProductStatus.Unknown }
  }

  resolveOrderStatusBadgeSettingsNew(data: OrderTypes.OrderListItemModel): BadgeSetting {
    switch (data.status) {
      case OrderTypes.OrderStatus.Cancelled:
        return { color: 'warning', title: OrderProductStatus.Cancelled }

      case OrderTypes.OrderStatus.InProduction:
        return { color: 'in-progress', title: OrderProductStatus.InProduction }

      case OrderTypes.OrderStatus.OnHold:
        return { color: 'warning', title: OrderProductStatus.OnHold }

      case OrderTypes.OrderStatus.PaymentFailed:
        return { color: 'danger', title: OrderProductStatus.PaymentFailed }

      case OrderTypes.OrderStatus.PaymentSucceeded:
        return { color: 'in-progress', title: OrderProductStatus.PaymentSucceeded }

      case OrderTypes.OrderStatus.ScannedForShipment:
        return {
          color: 'success',
          title: data.products.every((p) => p.productId === VoucherProductId)
            ? OrderProductStatus.ShippedVoucher
            : OrderProductStatus.ScannedForShipment,
        }

      case OrderTypes.OrderStatus.WaitingConfirmation:
        return { color: 'waiting', title: OrderProductStatus.WaitingForPaymentNotConfirmed }

      case OrderTypes.OrderStatus.WaitingForPaymentPending:
      case OrderTypes.OrderStatus.WaitingForPayment:
        return { color: 'waiting', title: OrderProductStatus.WaitingForPayment }

      case OrderTypes.OrderStatus.WaitingForPaymentConfirmed:
        return { color: 'waiting', title: OrderProductStatus.WaitingForPaymentConfirmed }

      case OrderTypes.OrderStatus.WaitingForProduction:
        return { color: 'waiting', title: OrderProductStatus.WaitingForProduction }

      case OrderTypes.OrderStatus.Delayed:
        return { color: 'warning', title: OrderProductStatus.Delayed }

      default:
        return { color: 'other', title: OrderProductStatus.Unknown }
    }
  }

  resolveShipmentsText(deliveryInfo: OrderTypes.TraceWithProducts[]): string | undefined {
    const filtered = _(deliveryInfo)
      .filter((x) => !!x.scannedDate || !!x.trackingId)
      .value()

    switch (filtered.length) {
      case 0:
        return undefined
      case 1:
        return toDate(filtered[0].scannedDate) || 'View'
      default:
        return `View all (${filtered.length})`
    }
  }

  resolveOrderEventsByType(orderEvents: OrderTypes.OrderEvent[], type: OrderEvents): OrderTypes.OrderEvent[] {
    if (!orderEvents) {
      return []
    }

    return orderEvents.filter((x) => x.event === type)
  }

  resolveResetOrderEvents(orderEvents: OrderTypes.OrderEvent[], productionOrderId: string): OrderTypes.OrderEvent[] {
    if (!orderEvents) {
      return []
    }
    return orderEvents.filter(
      (x) =>
        (x.event === OrderEvents.FullReset || x.event === OrderEvents.PartialReset) && x.productionOrderId === productionOrderId
    )
  }

  resolveProductEvents(orderEvents?: OrderTypes.OrderEvent[], productCode?: string): OrderTypes.OrderEvent[] {
    if (!orderEvents) {
      return []
    }

    return orderEvents.filter((oe) => oe.productCode === productCode)
  }

  resolvePromisedCampaign(orderEvents: OrderTypes.OrderEvent[]): OrderCampaignInfo | undefined {
    const [promiseEvent] = this.resolveOrderEventsByType(orderEvents, OrderEvents.Promised)
    const [unpromisedEvent] = this.resolveOrderEventsByType(orderEvents, OrderEvents.Unpromised)
    const [promiseBrokenEvent] = this.resolveOrderEventsByType(orderEvents, OrderEvents.PromiseBroken)

    const unpromised = !!unpromisedEvent && !!promiseEvent && unpromisedEvent.timestamp > promiseEvent.timestamp
    const promiseBroken =
      (!!promiseBrokenEvent && unpromisedEvent == null) || promiseBrokenEvent?.timestamp > unpromisedEvent?.timestamp

    if (!promiseEvent) {
      return undefined
    }

    return { campaignName: promiseEvent.comment, unpromised: unpromised, isBrokenPromise: promiseBroken }
  }

  resolveProductReuploadState(orderEvents?: OrderTypes.OrderEvent[], productCode?: string): ReuploadInfo | undefined {
    const productEvents = this.resolveProductEvents(orderEvents, productCode)
    return this.resolveReuploadState(productEvents)
  }

  resolveReuploadState(orderEvents?: OrderTypes.OrderEvent[]): ReuploadInfo | undefined {
    if (!orderEvents) {
      return undefined
    }

    const [reuploaded] = this.resolveOrderEventsByType(orderEvents, OrderEvents.ReuploadedOriginal)
    if (reuploaded) {
      return {
        image: reuploadedIcon,
        title: 'Product reuploaded',
        state: ReuploadState.Completed,
      }
    }

    const [cancelled] = this.resolveOrderEventsByType(orderEvents, OrderEvents.CancelReupload)
    if (cancelled) {
      return {
        image: reuploadCancelledIcon,
        title: 'Reupload cancelled',
        state: ReuploadState.Cancelled,
      }
    }

    const [permitted] = this.resolveOrderEventsByType(orderEvents, OrderEvents.ReuploadPermitted)
    if (permitted) {
      return {
        image: reuploadPermittedIcon,
        title: 'Reupload requested',
        state: ReuploadState.Requested,
      }
    }

    return undefined
  }

  isDelayed(orderEvents: OrderTypes.OrderEvent[]): boolean {
    return this.resolveOrderEventsByType(orderEvents, OrderEvents.Delayed).length > 0
  }

  getDelayedProductCodes(orderEvents?: OrderTypes.OrderEvent[]): string[] {
    if (!orderEvents) {
      return []
    }

    const delayedProductCodes = orderEvents
      .filter((x) => x.event === OrderEvents.Delayed && x.productCode)
      .map((x) => x.productCode || '')

    return _.uniq(delayedProductCodes)
  }

  resolveProductPreviewUrl(product: Product): string {
    if (!product.preview || !product.preview.url) {
      return defaultImage
    }

    return product.preview.url
  }

  resolveProductionOrderProduct(
    product: Product,
    productionOrders: ProductionOrder[] | undefined
  ): ProductionOrderProduct | undefined {
    if (!productionOrders) {
      return undefined
    }

    const productionOrderProducts = productionOrders
      .map((po) => po.shipments.map((s) => s.productsData).reduce((a, b) => a.concat(b), []))
      .reduce((a, b) => a.concat(b), [])

    const matchingProductionOrder = productionOrderProducts.find((p) => p.id.toUpperCase() === product.id.toUpperCase())

    return matchingProductionOrder
  }

  resolveProductStatuses(product: Product, parentStatus: BadgeSetting): Array<BadgeSetting> {
    const statusMap = new Map<number, number>()

    product.lines.forEach((l) => {
      const val = (statusMap.get(l.status) || 0) + 1
      statusMap.set(l.status, val)
    })

    const result: Array<BadgeSetting> = []

    statusMap.forEach((qty, status) => {
      const badgeSettings = this.fromOrderLineStatusToBadgeSettings(product.id, status.toString(), product.productId, qty)
      result.push(badgeSettings)
    })

    // fallback for non-FF statuses - override with parent status
    if (result.length === 1 && result[0].title === OrderProductStatus.NotAvailable) {
      return [{ ...parentStatus, qty: product.lines.length }]
    }

    return result
  }

  resolveProductionOrderStatus(productionOrder: ProductionOrder): BadgeSetting {
    const badgeSettings = this.fromOrderLineStatusToBadgeSettings(productionOrder.id, productionOrder.status, productionOrder.id)
    return badgeSettings
  }

  resolveProductionOrderProductStatuses(
    product: ProductionOrderProduct,
    productionOrderId: string | undefined,
    productEvents: OrderEvent[],
    parentStatus: BadgeSetting
  ): Array<BadgeSetting> {
    const statusMap = new Map<ProductionOrderProductStatus, number>()

    const arr: ProductionOrderProductStatus[] = []
    productionOrderProductStatusMapping.forEach((value: ProductionOrderProductStatus, key: string) => {
      arr.splice(0, 0, value)
    })

    arr.forEach((status) => {
      statusMap.set(status, 0)
    })

    const eventPrefix = 'production-order-status-'
    const productStatuses: OrderEvent[] = productEvents.filter(
      (e) =>
        (e.productionOrderId === productionOrderId || e.comment?.includes(productionOrderId!)) &&
        (e.event.startsWith(eventPrefix) || e.status)
    )

    const groups = _.groupBy(
      productStatuses,
      (e) => e.status || eventNameToStatusValueMap.get(e.event.substring(eventPrefix.length, e.event.length))
    )

    _.map(groups, (g, i) => {
      let status = productionOrderProductStatusMapping.get(i) || ProductionOrderProductStatus.Unknown
      if (status === ProductionOrderProductStatus.Delayed || status === ProductionOrderProductStatus.Error) {
        statusMap.set(status, product.quantity)
      } else {
        let sum = 0
        g.forEach((k) => {
          sum += k.quantity || 0
        })
        if (sum > product.quantity || sum === 0) {
          sum = product.quantity
        }
        statusMap.set(status, sum)
      }
    })

    const result: Array<BadgeSetting> = []

    let remainingItems = product.quantity

    statusMap.forEach((qty, status) => {
      if (qty > 0 && remainingItems > 0) {
        var take = remainingItems - qty > 0 ? qty : remainingItems
        const badgeSettings = this.fromProductionOrderProductStatusToBadgeSettings(
          product.id,
          status.toString(),
          product.articleCode,
          take
        )
        result.push(badgeSettings)
        remainingItems -= qty
      }
    })

    // fallback for non-FF statuses - override with parent status
    if (result.length === 1 && result[0].title === OrderProductStatus.NotAvailable) {
      return [{ ...parentStatus, qty: product.quantity }]
    }

    return result
  }

  resolveProductEditor(editor?: Editor): EditorDescription {
    const description = editor ? this.resolveEditorTitle(editor.name) : unknownEditor

    return {
      ...description,
      version: editor && editor.version,
    }
  }

  resolveEditor(editor?: Editor): EditorDescription {
    const description = editor ? this.resolveEditorTitle(editor.name) : unknownEditor

    return {
      ...description,
      version: editor && editor.version,
    }
  }

  resolveEditorTitle(name?: string): EditorDescription {
    if (!name) {
      return unknownEditor
    }

    return editorDescriptions[name] || { name: name, type: '?' }
  }

  resolveCarrierName(carrierName: string | undefined): string | undefined {
    if (!carrierName) {
      return undefined
    }

    var result = carrierNamesMapping[carrierName]

    if (!result) {
      return carrierName
    }

    return result
  }

  isProductLayflat(product: Product | undefined): boolean | undefined {
    if (!product) {
      return undefined
    }

    const binding = product?.options?.find((o) => o.key === 'Binding')

    if (!binding) {
      return undefined
    }

    if (binding.value === 'Normal') {
      return false
    }

    return true
  }

  fromOrderLineStatusToBadgeSettings(productId: string, status: string, pap: string, qty?: number): BadgeSetting {
    switch (status.toLocaleLowerCase()) {
      case 'new':
        return { color: 'waiting', title: status, qty: qty }
      case OrderLineStatus.WaitingForProduction.toString():
        return { color: 'waiting', title: OrderProductStatus.WaitingForProduction, qty: qty }
      case OrderLineStatus.InProduction.toString():
        return { color: 'in-progress', title: OrderProductStatus.InProduction, qty: qty }
      case OrderLineStatus.Packed.toString():
        return { color: 'in-progress', title: OrderProductStatus.Packed, qty: qty }
      case OrderLineStatus.ScannedForShipment.toString():
        return {
          color: 'success',
          title: pap === VoucherProductId ? OrderProductStatus.ShippedVoucher : OrderProductStatus.ScannedForShipment,
          qty: qty,
        }
      case OrderLineStatus.Error.toString():
        return { color: 'danger', title: OrderProductStatus.Error, qty: qty }
      case OrderLineStatus.Cancelled.toString():
        return { color: 'warning', title: OrderProductStatus.Cancelled, qty: qty }
      case OrderLineStatus.Unknown.toString():
        return { color: 'other', title: OrderProductStatus.NotAvailable, qty: qty }
      default:
        LogApiInstance.error(
          new LogMessage(
            {
              message: 'Failed to resolve the product status.',
              productId,
            },
            codes.orderDetailsError
          )
        )
        return { color: 'other', title: status, qty: qty }
    }
  }

  fromProductionOrderProductStatusToBadgeSettings(productId: string, status: string, pap: string, qty?: number): BadgeSetting {
    switch (status.toLocaleLowerCase()) {
      case 'new':
        return { color: 'waiting', title: status, qty: qty }
      case ProductionOrderProductStatus.Accepted.toString():
        return { color: 'waiting', title: OrderProductStatus.Accepted, qty: qty }
      case ProductionOrderProductStatus.WaitingForProduction.toString():
        return { color: 'waiting', title: OrderProductStatus.WaitingForProduction, qty: qty }
      case ProductionOrderProductStatus.InProduction.toString():
        return { color: 'in-progress', title: OrderProductStatus.InProduction, qty: qty }
      case ProductionOrderProductStatus.Delayed.toString():
        return { color: 'warning', title: OrderProductStatus.Delayed, qty: qty }
      case ProductionOrderProductStatus.Packed.toString():
        return { color: 'in-progress', title: OrderProductStatus.Packed, qty: qty }
      case ProductionOrderProductStatus.PartiallyShipped.toString():
        return { color: 'in-progress', title: OrderProductStatus.PartiallyShipped, qty: qty }
      case ProductionOrderProductStatus.ScannedForShipment.toString():
        return {
          color: 'success',
          title: pap === VoucherProductId ? OrderProductStatus.ShippedVoucher : OrderProductStatus.ScannedForShipment,
          qty: qty,
        }
      case ProductionOrderProductStatus.Error.toString():
        return { color: 'danger', title: OrderProductStatus.Error, qty: qty }
      case ProductionOrderProductStatus.Cancelled.toString():
        return { color: 'warning', title: OrderProductStatus.Cancelled, qty: qty }
      case ProductionOrderProductStatus.Unknown.toString():
        return { color: 'other', title: OrderProductStatus.NotAvailable, qty: qty }
      default:
        LogApiInstance.error(
          new LogMessage(
            {
              message: 'Failed to resolve the product status.',
              productId,
            },
            codes.orderDetailsError
          )
        )
        return { color: 'other', title: status, qty: qty }
    }
  }

  getOrderlinesCount(data: OrderTypes.OrderDetails): number | undefined {
    if (!data.products || data.products.length === 0 || data.products.every((p) => !p.lines || p.lines.length === 0)) {
      return undefined
    }

    return data.products
      .filter((p: Product) => p.lines && p.lines.length > 0)
      .map((p) => p.lines.length)
      .reduce((acc: number, cur: number) => acc + cur)
  }

  getShipmentsCount(data: OrderTypes.OrderDetails): number | undefined {
    if (!data.deliveryInfo) {
      return undefined
    }

    return data.deliveryInfo.length
  }

  getProductOptions(product?: Product): ProductOptionItem[] | undefined {
    if (!product || !product.options || product.options.length === 0) {
      return undefined
    }
    const options = product.options
    const result: ProductOptionItem[] = []

    for (let index = 0; index < options.length; index++) {
      const option = options[index]
      const resolvedName = OrderTypes.ProductOptionNames[option.key]
      if (!resolvedName) {
        continue
      }

      let value = option.value

      if (option.key === 'ArticleType') {
        value = `${ArticleTypeNames[value]}`
      }

      if (option.key === 'CoverType' && Number(option.value)) {
        value = `${CoverColorNames[value]} (${value})`
      }

      if (option.key === 'PaperType') {
        const resolvedName = PaperTypeNames[value]
        if (resolvedName) {
          value = `${resolvedName}`
        }
      }

      if (option.key === 'PrintSurface') {
        value = `${PrintSurfaceNames[value]} (${value})`
      }

      if (option.key === 'FloatingFrame') {
        value = `${FrameColorNames[value]}`
      }

      if (option.key === 'PosterFrame') {
        value = `${FrameColorNames[value]}`
      }

      if (option.key === 'PrebuiltPosterFrame') {
        value = `${FrameColorNames[value]}`
      }

      if (option.key === 'Passepartout') {
        value = `${PassepartoutNames[value]}`
      }

      if (option.key === 'Border') {
        value = `${BorderNames[value]}`
      }

      if (option.key === 'Color') {
        value = `${ColorNames[value]}`
      }

      if (option.key === 'Material') {
        value = `${MaterialNames[value]}`
      }

      if (option.key === 'PhotoSide') {
        value = `${PhotoSideNames[value]}`
      }

      result.push({ name: resolvedName, value: value })
    }

    return result
  }

  getShippableProductionOrders(productionOrders: ProductionOrder[]) {
    if (!productionOrders || productionOrders.length === 0) {
      return undefined
    }

    const shippableProductionOrders = productionOrders.filter((po) => po.status !== 'Cancelled' && po.status !== 'Shipped')

    return shippableProductionOrders
  }

  resolveProductDescriptionByCode(data: OrderTypes.OrderDetails, productCode: string | undefined): string | undefined {
    if (!data.products) {
      return undefined
    }

    const [product] = data.products.filter((p) => p.id === productCode)

    if (!product) {
      return undefined
    }

    const productType = product.productId

    return this.resolveProductDescriptionByType(productType)
  }

  // productType = PAP
  resolveProductDescriptionByType(productType?: string): string | undefined {
    return productType && ProductTypeNames[productType.toUpperCase()]
  }

  resolveStudentenProductDescriptionByType(productType?: string): string | undefined {
    let productDescription: string | undefined = undefined
    if (productType) {
      productDescription = studentenProductTypeNames[productType]

      if (!productDescription) {
        productDescription = productType
      }
    }

    return productDescription
  }

  // The key is formatted as 'ProductionOrderId:ProductId'
  createCompoundNotesSecondaryKey(productionOrderId: string, productId: string) {
    return productionOrderId + ':' + productId
  }

  filterNotes(notes: Note[] | undefined, productId: string): Note[] {
    return notes
      ? notes.filter(
          (note) =>
            !note.secondaryKey ||
            note.secondaryKey
              .split(',')
              .map((x) => x.trim())
              .some((x) => x === productId)
        )
      : []
  }

  filterNotesByProductionOrderId(notes: Note[] | undefined, productionOrderId: string): Note[] {
    return notes
      ? notes.filter(
          (note) =>
            !note.secondaryKey ||
            note.secondaryKey
              .split(',')
              .map((x) => x.trim())
              .some((x) => x.startsWith(productionOrderId))
        )
      : []
  }

  filterNotesByProductionOrderAndProductId(notes: Note[] | undefined, productionOrderId: string, productId: string): Note[] {
    return notes
      ? notes.filter(
          (note) =>
            !note.secondaryKey ||
            note.secondaryKey
              .split(',')
              .map((x) => x.trim())
              .some((x) => x === this.createCompoundNotesSecondaryKey(productionOrderId, productId) || x === productionOrderId)
        )
      : []
  }

  filterSbsCollection(
    sbs: ShowByScanRecord[] | undefined,
    productId?: string,
    productionOrderId?: string
  ): ShowByScanRecord[] | undefined {
    if (!sbs) {
      return undefined
    }

    let filled = sbs.filter((x) => x.type !== undefined)

    if (productionOrderId) {
      return filled.filter((x) => x.productionOrderId === productionOrderId)
    }

    if (!productId) {
      return filled
    }

    return filled.filter((x) => x.productId === productId)
  }

  extractSbsCollection(order: OrderTypes.OrderListItemModel): ShowByScanRecord[] | undefined {
    if (!order.internalOperations) {
      return undefined
    } else {
      const allSbsMessages = order.internalOperations.filter((e) => e.operation.indexOf('sbs') !== -1)
      const groups = _.groupBy(allSbsMessages, (e) => e.productCode || e.productionOrderId)
      const filtered = _.map(groups, (g, i) => {
        const ordered = _.orderBy(g, (x) => x.timestamp, 'desc')
        const [last] = ordered
        if (!last || last.operation !== 'sbs-update') {
          return null
        } else {
          return {
            createdAt: last.timestamp,
            productId: last.productCode,
            productionOrderId: last.productionOrderId,
            text: last.comment,
            type: Number(last.reasonCode),
            username: last.causedBy,
          } as ShowByScanRecord
        }
      })
        .filter((x) => x !== null)
        .map((x) => x as ShowByScanRecord)
      return filtered
    }
  }

  getPlantInfo(plant?: string): PlantInfo {
    if (!plant) {
      return {
        plant: FulfillmentPlant.UNKNOWN,
        displayName: 'Unknown at the moment',
      }
    }

    return {
      plant: plant as FulfillmentPlant,
      displayName: plantNames[plant] || plant,
    }
  }

  getPlantsFromProductionOrders(productionOrders?: ProductionOrder[]): PlantInfo[] {
    const asList = _.chain(productionOrders)
      .map((po) => po.plant)
      .value()

    return this.transformPlants(asList)
  }

  getPlantsFromOrderEvents(orderEvents?: OrderEvent[], productCode?: string): PlantInfo[] {
    let orderEventsContainingPlantCode = this.getOrderAcceptedEventsForProduct(orderEvents, productCode)

    if (orderEventsContainingPlantCode!.length > 0) {
      let plant = _.orderBy(orderEventsContainingPlantCode, (event) => event.timestamp, 'desc')[0].plant
      return this.transformPlants([plant])
    }

    return this.transformPlants([])
  }

  getOrderAcceptedEventsForProduct(orderEvents?: OrderEvent[], productCode?: string | undefined): OrderEvent[] {
    let orderAcceptedEvents = orderEvents!.filter((e) => e.event === 'accepted')

    if (productCode == undefined) {
      return orderAcceptedEvents
    }

    return orderAcceptedEvents.filter((e) => e.productCode === productCode)
  }

  transformPlants(plantsList?: (string | undefined)[]): PlantInfo[] {
    if (!plantsList || plantsList.length === 0) {
      return [this.getPlantInfo()]
    }
    return _(plantsList)
      .filter((plant) => !!plant)
      .uniq()
      .map(this.getPlantInfo)
      .value()
  }
}

export const OrderDetailsServiceInstance: OrderDetailsService = new OrderDetailsService()
