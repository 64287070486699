import _ from 'lodash'
import React, { ReactNode } from 'react'
import { OrderValueRecord, ProductValueRecord } from '../../services/RefundCalculatorService'
import { toDateTime } from '../../utils'
import { OrderDetails } from '../../api'
import { OrderDetailsServiceInstance as Service } from '../../services/OrderDetailsService'
import defaultImage from '../../assets/img/placeholder-image.png'

interface Props {
  orderValue: OrderValueRecord,
  order?: OrderDetails,
  //todo: potentially obsolete
  title?: string,
  children?: ReactNode,
}

export default class OrderValueCard extends React.Component<Props> {
  constructor(props: Props) {
    super(props)

    this.getProductImage = this.getProductImage.bind(this)
    this.getProductTitle = this.getProductTitle.bind(this)
  }

  render() {
    const orderValue = this.props.orderValue

    const orderedProducts = [...orderValue.productValueRecords]
    orderedProducts.sort((a: ProductValueRecord, b: ProductValueRecord) => a.productCode.localeCompare(b.productCode))

    const totalValue = _.sum(orderedProducts.map((x) => x.value)) + orderValue.shippingCostValue
    return (
      <>
        <div className='order-value-record'>
          <table className="bo-grid-vertical wide">
            <colgroup>
              <col className='col-4' />
              <col />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th>{this.props.title ?? ''}</th>
                <td colSpan={2}>{orderValue.orderCode}</td>
              </tr>
              <tr>
                <th>Total amount</th>
                <td colSpan={2}>{concatCurrency(totalValue, orderValue.currency)}</td>
              </tr>
              <tr>
                <th>Reason</th>
                <td colSpan={2}>{orderValue.reason}</td>
              </tr>
              <tr>
                <th>Date</th>
                <td colSpan={2}>{toDateTime(orderValue.date)}</td>
              </tr>
              {renderHorizontalLine()}
              {orderedProducts.map(x => (
                <tr key={x.productCode}>
                  <td>
                    <div className='product-preview'>
                      <img src={this.getProductImage(x.productCode)} alt="preview" />
                    </div>
                  </td>
                  <td>
                    <div className='flex-column product-code'>
                      <div><strong>{this.getProductTitle(x.productCode)}</strong></div>
                      <div className='order-value-record-thin'>{x.productCode}</div>
                      <div className='order-value-record-thin'>{`(qty:${x.quantity})`}</div>
                    </div>
                  </td>
                  <td>{concatCurrency(x.value, orderValue.currency)}</td>
                </tr>
              ))}
              <tr>
                <th>ShippingCosts</th>
                <td colSpan={2}>{concatCurrency(orderValue.shippingCostValue, orderValue.currency)}</td>
              </tr>
            </tbody>
          </table>
          {this.props.children &&
            <div className='d-flex flex-row-reverse'>
              {this.props.children}
            </div>
          }
        </div>
      </>
    )
  }

  private getProductImage(productCode: string): string {
    const product = this.props.order?.products.find((p) => p.id === productCode)
    if (product) {
      const image = Service.resolveProductPreviewUrl(product)
      return image
    }

    return defaultImage
  }

  private getProductTitle(productCode: string): string | undefined {
    const product = this.props.order?.products.find((p) => p.id === productCode)
    return product?.title
  }
}


function concatCurrency(value?: number, currency?: string) {
  if (value !== undefined) {
    const currencyFormattedNumber = (Math.round(value * 100) / 100).toFixed(2);
    return currency !== undefined ? `${currencyFormattedNumber} ${currency}` : value
  }
  return undefined
}

function renderHorizontalLine() {
  return (
    <tr className="delimiter">
      <th colSpan={3}>
        <hr />
      </th>
    </tr>
  )
}